import React,{useRef} from "react";


export default function NeoLayout({layout}) {
  const styleElement = useRef()
  const setLayout = (data) => {
    
    if(data.box){
      const css = `
      
        .slick-dots li button:before {
          color: ${data.color} !important;
        }
      .mobile__body{
        /* background-color: ${data.corBackground};
        background-image: url(${data.background});
        color: ${data.colorText}; */
        
        background-color: #eaeaea;
        color: #000000; 
      }
      .body{
        background-color: ${data.corBackground};
        background-image: url(${data.background});
        color: ${data.colorText};
      }
      .form__ou{
        color: ${data.colorText};
      }
      .form__ou hr{
        border-color: ${data.colorText};
      }
      .layout__bullets {
        background-color: ${data.color};
      }
      .file__input a:hover{
        
          color: ${data.color} !important;
        
      }
      .content_contato {
        background-color: ${data.box};
      }
      .form__btn--secundary, .form__btn{
        background-color: ${data.color};
      }
      .form__btn--secundary:hover, .form__btn:hover{
        background-color: ${data.color2};
      }
      .form__popup{
        background-color: ${data.box};
        color: ${data.colorText};
        // border: none;
        // box-shadow: 5px 5px 25px #00000073;

      }
      .form__loading .Login__icon {
        color: ${data.color};
        filter: none;
      }
      .content_iconBuy{
        color: ${data.color};
      }
      .content_textBuy{
        color: ${data.color};
      }
      .slider_button{
        background-color: ${data.box};
        color: ${data.colorText};
      }
      .controls_progress_inner {
        background-color: ${data.color};
      }
      .controls_volume_inner {
        background-color: ${data.color};
      }
   
      .audio_volume_inner {
        background-color: ${data.color};

      }
      .audio_progress_inner{
        background-color: ${data.color};
      }
      div.playlist_currentSong{
        box-shadow: 0 0 0 0 ${data.color};
        background: ${data.color};

      }
      .Perfil_headerIcon{
        color: ${data.colorText};

      }
      .perfil_page.joyceLayout{
        background-color: ${data.box};
      }
      .page__content.joyceLayout{
        background-color: ${data.corBackground};

      }
      .form__btn--secundary.joyceLayout{
        color: ${data.colorText};

      }
      .form__btn--secundary.joyceLayout:hover{
        background-color: ${data.color};
      }
      .page__content {
        color: ${data.text};
      }
      `
      if(styleElement.current)styleElement.current.innerHTML=css;
    }
    
  }
  
  return (
    <style ref={styleElement} id="layout_neo">
      {setLayout(layout)}
    </style>
  );
}
