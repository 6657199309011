import React,{useContext} from 'react';
import {htmlDecode} from "../utils"
import { audioDataContext,contentDataContext } from "../context";

export default function Playlist({playlist}) {
  
  const { item } = useContext(contentDataContext)
  const { itemContext } = item

  const { audio, song } = useContext(audioDataContext)
  const { audioContext,setAudioContext } = audio
  const { currentSongContext, setCurrentSongContext } = song

  const isEqualData = (data1,data2) => {
    return JSON.stringify(data1) === JSON.stringify(data2)
  }

  const selectSong = (index) => {
    if(!isEqualData(itemContext,audioContext)) setAudioContext(itemContext)
    setCurrentSongContext(index)
  }
  
  return(
    <div className="audio_playlist">
      <div className="playlist_title">
        <h4>Playlist</h4>
      </div>
      <ul>
      {playlist?.map((item, index)=>{
        if( index===currentSongContext && isEqualData(itemContext,audioContext)
          ) return (
          <li  key={item.id} onClick={() => selectSong(index)} className="playlist_currentSong" >
            <div className="playlist_currentSong" />{htmlDecode(item.titulo)}
          </li>
        )

        else   return <li onClick={() => selectSong(index)} key={item.id}>{htmlDecode(item.titulo)}</li>
      })}
      </ul>
    </div>
  )

}
