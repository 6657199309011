import React from 'react';

export default function Select({
    input,
    AddDataField,
    confirmInput,
    className,
    registerCustomData
}) {

    return (
        <select 
            name={input.nome} 
            placeholder={input.placeholder}
            onChange={e => AddDataField(input, e.target.value)} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
            className={className}
        >
            <option value="">{input.placeholder}</option>
            {input.opcoes.map(option=>{
                return <option key={option.codigo} selected={registerCustomData[input.slug]?.toString()===option.codigo} value={option.codigo}>{option.valor}</option>
            })}
        </select>
    );
    
   
}       
        
