import React, {useState, useEffect, useRef} from 'react'
import useNavigationSetup from '../../hooks/useNavigationSetup';

import { faEnvelope, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AddNeoCode} from '../../api';
import {useHistory} from 'react-router-dom'

export default function AddNeo({text}) {
    const setNavi = useNavigationSetup()
    
    const history = useHistory();

    const [input1, setInput1] = useState("");
    const [input2, setInput2] = useState("");
    const [input3, setInput3] = useState("");
    const [input4, setInput4] = useState("");
    const [input5, setInput5] = useState("");
    const [input6, setInput6] = useState("");
    const inputsRef = useRef()
    const messageRef = useRef()
    const submitRef = useRef()

    useEffect(() => {
        const navigation={
            logo:true,
            menu:true,
            back: true,
          }
          setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const passInput = el =>  {
        if(el.nextSibling&&el.value!==""){
            el.nextSibling.focus()
        }
    }

    const handleCodeSubmit = async event =>  {
        event.preventDefault();
        try{
            const code = input1+input2+input3+input4+input5+input6;
            await AddNeoCode(code);
            removeElements()
            showMessage(text.neoAdicionado)
        }catch(e){
            showMessage(e);
        }
    }
    const removeElements = ()=>{
        inputsRef.current.remove();
        submitRef.current.remove();
    }
    const showMessage = (value)=>{
        messageRef.current.innerHTML= value
    }


    return (
        <div className="page__placement">
            
            <form  onSubmit={handleCodeSubmit} className="page__content">
                
                    <FontAwesomeIcon className="PopUp__icon hideLoading" icon={faEnvelope} />

                    <p ref={messageRef} className="popup__title hideLoading">{text.insiraCodigo}</p>

                    <div ref={inputsRef}className="input__slots hideLoading">
                        <input 
                            
                            required 
                            id="Input1"
                            autoFocus
                            value={input1}
                            onChange={e => setInput1(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                        <input 
                            required 
                            id="Input2"
                            value={input2}
                            onChange={e => setInput2(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                        <input 
                            required 
                            id="Input3"
                            value={input3}
                            onChange={e => setInput3(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                        <input 
                            required 
                            id="Input4"
                            value={input4}
                            onChange={e => setInput4(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                        <input 
                            required 
                            id="Input5"
                            value={input5}
                            onChange={e => setInput5(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                        <input 
                            required 
                            id="Input6"
                            value={input6}
                            onChange={e => setInput6(e.target.value)}
                            onKeyUp={e => passInput(e.target)}
                            type="text"
                            className="form__input"
                            maxLength="1"
                        />
                    </div>

                    <div className="disable" id='menssagememail'>
                        <FontAwesomeIcon className="Login__icon spin" icon={faCircleNotch} />
                    </div>
                    

                    <div  className="popup__response">
                        <button id="cancelBtn" onClick={history.goBack} type="reset" className="form__btn--secundary">{text.voltar}</button>
                        <button ref={submitRef} type="submit" className="form__btn--secundary hideLoading">{text.enviar}</button>
                    </div>
                

                
            </form>
            
        </div>
    )
}
