import React, { useState } from 'react';
import {isSafari} from 'react-device-detect';

export default function Date({
    input,
    AddDataField,
    confirmInput,
    registerCustomData,
    className
}) {
    const [dateToShow, setDateToShow] = useState(dataFormat(registerCustomData[input.slug]));
    
    function dateMask(value) {
        return value
        .replace(/\D/g, "") 
        .replace(/(\d{2})(\d)/, "$1/$2") 
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\/\d{4})\d+?$/, "$1");        
      }
    // format date
    function dataFormat(date) {
        const data = date?.substr(8, 2)+"/"+date?.substr(5, 2)+"/"+date?.substr(0, 4);
            return data;
    }

    // date send
    function dataSendFormat(value){
        const data = value.split('/')
          return data[2]+"-"+data[1]+"-"+data[0]
    }

    function updateData(confirm, elementInput) {
        AddDataField(input, dataSendFormat(elementInput.value))
        if (confirm) confirmInput(elementInput)  
    } 

      if (isSafari) {
          return (
              <input 
              name={input.nome} 
              type="text"
              className={className}
              placeholder="DD/MM/AAAA"
              onChange={e => setDateToShow(dateMask(e.target.value))} 
              onBlur={e => updateData(input.obrigatorio, e.target)} 
              value={dateToShow}
              />
          );
      } else {
            return (
                <input 
                name={input.nome} 
                type="date"
                max="9999-12-31"
                className={className}
                placeholder="DD/MM/AAAA"
                onChange={e => AddDataField(input, e.target.value)} 
                onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
                value={registerCustomData[input.slug]}
                />
            );
        }
}       
        
