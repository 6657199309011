import React, {useEffect, useState, useRef, useContext} from 'react';
import { confirmAlert } from 'react-confirm-alert'
import '../styles/global.scss'

import {useDispatch} from "react-redux";
import {setSearch} from "../redux/actions";
import {layoutContext} from '../context';

import { faSearch, faPlusCircle, faChevronLeft, faBars, faUserCircle, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {destroyToken} from '../utils';
import {useHistory, useRouteMatch} from 'react-router-dom'

import Menu from './menu'

export default function Navegacao({setup,hideLogo}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const router = useRouteMatch();

    const { layout , textList } = useContext(layoutContext)
    const { setLayoutData} = layout
    const { text } = textList

    const [menu, setMenu] = useState(false);
    const [steachStatus, setSteachStatus] = useState(false);

    const searchInputElement = useRef(null)

    const logout = (mdi_id) => {
        destroyToken();
        if(mdi_id) history.push(`/`+mdi_id);
        else {
            history.push(`/`);
            setLayoutData({})
        }
    }
    const goToProfile =  (mdi_id) => {
        history.push(`/carrossel/${mdi_id}/profile`);
    }
    const ativarMenu =  () => {
        if(menu){
            setMenu(false)
        }else {
            setMenu(true)
        }
    }
    const urlPathName = () =>{
        const isNeo = window.location.pathname.includes(`/neo/`)
        const isCarrossel = window.location.pathname.split('/').length===3
        return isNeo && isCarrossel
    }
    const addNeo = () => {
        history.push(`/home/addneo`);
    }
    const showHideInput = () => {
        searchInputElement.current.focus()
        setSteachStatus(steachStatus=>{
            hideLogo(!steachStatus)
            return setSteachStatus(!steachStatus)
        })
        clearSearch()
    }
    const clearSearch = () => {
        dispatch(setSearch(""))
        searchInputElement.current.value = ""
    }
    useEffect(() => {
        if(history.action===("POP"||"PUSH")) {
            dispatch(setSearch(""))
            setSteachStatus(false)
        }
    }, [router])


        return (<>
                <div id="menu_container" className="Login__navi">
                    {setup.search? (<>
                        <div 
                        className={
                            steachStatus?
                            "search_container " 
                            :"search_container invisible" 
                            }>

                            <input 
                                ref={searchInputElement}
                                id="searchInput"
                                className="home__search form__input" 
                                onChange={e => dispatch(setSearch(e.target.value))}                                
                                >
                            </input>
                            <button onClick={clearSearch} className="search_clearButton">X</button>
                        </div>
                        <FontAwesomeIcon onClick={showHideInput} className="Login__icon" icon={faSearch} /></>
                    ):<></>}
                    
                    {setup.back && !urlPathName() ? (
                                <FontAwesomeIcon onClick={() => history.goBack()} className="Login__icon" icon={faChevronLeft} />
                    ):<></>}  
                    
                    
                    {setup.addNeo? (
                        <FontAwesomeIcon onClick={addNeo} className="Login__icon" icon={faPlusCircle} />
                    ):<></>}
                    {setup.view==="carrossel"? (
                        <>
                            <FontAwesomeIcon className="Login__icon" icon={faUserCircle} onClick={()=>goToProfile(setup.mdi_id)}/>
                            <FontAwesomeIcon className="Login__icon" icon={faSignOutAlt} onClick={() => {
                                const msg = "Você tem certeza que deseja sair?"
                                const addDialog =({onClose}) => {
                                    const handleClickedNo = () => {onClose()}
                                    const handleClickedYes = () => {logout(setup.mdi_id); onClose()}
                                    return (
                                        <div className='add-dialog'>
                                            <p>{msg}</p>
                                            <div className="add-dialog-buttons">
                                                <button onClick={handleClickedNo}>Não</button>
                                                <button onClick={handleClickedYes}>Sim</button>
                                            </div>
                                        </div>
                                    )
                                }
                                confirmAlert({customUI: addDialog})
                            }} />
                        </>
                    ):(
                        setup.hideBars?<></>:<FontAwesomeIcon onClick={ativarMenu} className="Login__icon" icon={faBars} />
                    )}
                </div>

                {menu? (
                       <Menu text={text} mdi_id={setup.mdi_id} close={ativarMenu} logout={logout}/>
                ):<></>}
                
           
            </>
        );

        
  
    
    
    
}
 