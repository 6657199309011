import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../pages/Login";
import Home from "../pages/home";
import Register from "../pages/Register";
import Neo from "../pages/neo";
import PerfilMobile from "../pages/PerfilMobile";

export default function Routes() {
  return (
      <Switch>
        <Route path={`/home/:page?`} exact component={Home} />
        <Route path={`/register/:neo?`} exact component={Register} />
        <Route path={`/perfilmobile/:token`} component={PerfilMobile} />
        <Route path={`/:view/:mdi_id/:type?/:key?/:itemType?`} exact component={Neo} />
        <Route path={`/:mdi_id?`} exact component={Login} />
      </Switch>
  );
}
