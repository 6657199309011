import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

export default function PopUp() {
      
    const {popupData} = useSelector(state => state);
    
    const [isActive, setIsActive] = useState(false)

    const escClose = (e) =>{
        if(e.key === 'Escape'){
            closePopup();
        }
    }
    const closePopup = () =>{
        setIsActive(false);
    }
    useEffect(() => {
        if(popupData)setIsActive(true)
    }, [popupData])
    
    useEffect(() => {
        document.addEventListener("keydown", escClose, false);
        return () => {
            document.removeEventListener("keydown", escClose, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (  
        isActive&&popupData.text?<div className="popup__background">
            <div  className="form__popup">
            <p className="popup__title">{popupData.text}</p>
            <button onClick={popupData.function?popupData.function:()=>closePopup(false)} className="form__btn--secundary">Ok</button>
        </div>
        </div>:<></>
    );
 
    
}
 