import React from 'react';

export default function Checkbox({
    input,
    AddDataCheckbox,
    confirmOption,
    className,
    registerCustomData,
}) {


    return (
        <div className="options_group">
            {input.opcoes.map(item=>{
                return <div key={item.codigo} className="options_item">
                    <input 
                        type="checkbox" 
                        id={item.valor} 
                        name={input.nome} 
                        value={item.codigo}
                        className={className+" option"}
                        onChange={e => AddDataCheckbox(input, e.target)} 
                        onBlur={e => input.obrigatorio?confirmOption(e.target):{}} 
                        checked={registerCustomData[input.slug]?.includes(item.codigo)}
                    />
                    <label htmlFor={item.valor}>{item.valor}</label>
                </div>
            })}
        </div>
    );
    
   
}       
        
