import React from 'react';
import {useHistory} from 'react-router-dom'
import { faHome, faUserAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from 'react-confirm-alert'
import '../styles/global.scss'



export default function Menu({close, logout, text, mdi_id}) {
    const history = useHistory();
    const openPerfil = () => {
        if (mdi_id){
            history.push(`/carrossel/${mdi_id}/profile`)
            close()
        }else if(window.location.pathname!=="/home/perfil"){
            history.push(`/home/perfil`);
            close()
        } else {
            close()
        }
    }
    const openHome = () => {
        history.push(`/home`);
        close()
    }
    const openLogout = () => {
        if (mdi_id) {
            history.push(`/home`)
        } else {
           logout()
        }
    }


    return (
        <div className="header__menu--content">

            <div onClick={close} className="header__menu"> </div>

            <div className="menu__content"><div className="menu__titulo" ><p>{text.menu}</p><span onClick={close}>x</span></div>
                <ul  className="menu__lista">
                    <li onClick={openHome}>
                        <FontAwesomeIcon className="Login__icon--menu" icon={faHome} />
                            {text.home}
                    </li>
                    <li onClick={openPerfil}><FontAwesomeIcon className="Login__icon--menu" icon={faUserAlt} />
                    {text.perfil}
                    </li>
                    <hr></hr>
                    <li onClick={() => {
                        const msg = "Você tem certeza que deseja sair?"
                        const addDialog =({onClose}) => {
                            const handleClickedNo = () => {onClose()}
                            const handleClickedYes = () => {openLogout(); onClose()}
                            return (
                                <div className='add-dialog'>
                                    <p>{msg}</p>
                                    <div className="add-dialog-buttons">
                                        <button onClick={handleClickedNo}>Não</button>
                                        <button onClick={handleClickedYes}>Sim</button>
                                    </div>
                                </div>
                            )
                        }
                        confirmAlert({customUI: addDialog})
                    }}>
                        <FontAwesomeIcon className="Login__icon--menu" icon={faSignOutAlt} />
                        {text.logout}
                    </li>
                </ul>
            </div>
        </div>
    )   
}
 