import React, {useState, useRef} from 'react'
import {getLostPass} from '../api';

export default function ForgotPass({text,setForgetPassStatus,forgetPassStatus,lang}) {
    const [email, setEmail] = useState("");
   
    const titleRef = useRef()
    const inputRef = useRef()
    const submitRef = useRef()

    const handleForgotPassSubmit = async event =>  {
        event.preventDefault();
        try{
            await getLostPass(email,lang);
            showMessage(text.confirmacaoEsqueceuSenha)
            removeElements()
        }catch(e){
            showMessage(e);
        }
    }

    const removeElements = ()=>{
        inputRef.current.remove();
        submitRef.current.remove();
    }
    const showMessage = (value)=>{
        titleRef.current.innerHTML= value
    }

    return (
        forgetPassStatus?
            <div className="popup__background">
                <form  onSubmit={handleForgotPassSubmit} className="form__popup">

                    <p ref={titleRef} className="popup__title">{text.esqueceuSenha}</p>

                    <input 
                        placeholder={text.email}
                        ref={inputRef}
                        required 
                        id='inputEmail'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email" 
                        className="form__input"
                    />

                    <div className="popup__response">
                        <button onClick={()=>setForgetPassStatus(false)} type="button" className="form__btn--secundary">{text.voltar}</button>
                        <button ref={submitRef} id='btnSend' type="submit" className="form__btn--secundary">{text.enviar}</button>
                    </div>
                </form>
            </div>
            :<></>
        
    )
}
