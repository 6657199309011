import React,{useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import {Provider} from "react-redux";
import Store from "./redux/storage";

import {layoutContext} from './context';
import {userContext} from './context';

import LoginRoutes from './routes/login';
import Header from './components/header';
import Popup from './components/popup';
import Loading from './components/loading';

import {getText} from './utils';

import "./styles/global.scss";

function App() {

  const [layoutData, setLayoutData] = useState({})
  const [language, setLanguage] = useState(null)
  const [text, setText] = useState(getText())
  const [userData, setUserData] = useState({})
  const [professions, setProfessions] = useState([])
  const [countries, setCountries] = useState([])

  const layoutContextData = {
    layout:{layoutData,setLayoutData},
    neoLang:{language,setLanguage},
    textList:{text,setText}
  }
  const userContextData = {
    user:{userData,setUserData},
    prof:{professions,setProfessions},
    country:{countries,setCountries}
  }

  useEffect(() => {
    setLanguage(layoutData.idioma)
    setText(getText(layoutData.idioma))
  }, [layoutData])

  useEffect(() => {
    setLanguage(navigator.language)
    setText(getText())
  }, [])


  return (
    <div className="body">
      <BrowserRouter>
        <Loading />
        <Popup text={text}/>
        <layoutContext.Provider value={layoutContextData}>
        <userContext.Provider value={userContextData}>
        <Header/>
        <LoginRoutes/>
        </userContext.Provider>
        </layoutContext.Provider>
      </BrowserRouter>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
     <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);