import React from 'react';

export default function Album({img, title, description
}) {

  return(<div className="audio_capa">
      <img  alt="capa do album" src={img}/>
      <h3>{title}</h3>
      <span>{description}</span>
    </div>
  )

}
