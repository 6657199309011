import React,{useEffect, useState} from 'react'
import useNavigationSetup from '../../hooks/useNavigationSetup';

import {useSelector} from "react-redux";
import { useHistory } from "react-router-dom";

export default function NeoList({text,userData}) {

    const setNavi = useNavigationSetup()
    const history = useHistory();

    const [neosList, setNeosList] = useState(userData.listaNeos)

    const {searchInput} = useSelector(state => state);

    useEffect(() => {
        const navigation={
            logo:true,
            menu:true,
            search: true,
            addNeo:true
          }
          setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
            if(!searchInput||searchInput==="")setNeosList(userData.listaNeos)
            else {
                setNeosList(userData.listaNeos?.filter((neo) =>
                neo.nome
                .toLowerCase()
                .includes(searchInput?.toLowerCase())))
            }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput])

    const selectNeo = (codigo) =>{
        history.push(`/neo/${codigo}`)
    }

    return (
        <>
            <div className="home__neolist">
                {neosList?.map(neo => {
                    return (<button
                            key={neo.codigo} 
                            onClick={()=>selectNeo(neo.codigo)}
                            className="home__neocard">
                                <img  
                                className="home__neocardimg" 
                                alt={neo.nome} 
                                src={neo.imagem}/>
                                <div className="home__neolabel">
                                    <p>{neo.nome}</p>
                                </div>
                            </button>)
                })}
                {userData.listaNeos?.length===0?<h2>{text.semResultado}</h2>:<></>}
            </div>
        </>
    )
  
}
