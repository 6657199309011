import React, { useState } from 'react';
import Date from '../Inputs/Date';
import Text from '../Inputs/Text';
import File from '../Inputs/File';
import TextArea from '../Inputs/TextArea';
import Select from '../Inputs/Select';
import Checkbox from '../Inputs/Checkbox';
import Radio from '../Inputs/Radio';
import PhoneNumber from '../Inputs/PhoneNumber';
import PhoneNumberLocal from '../Inputs/PhoneNumberLocal';
import Birthday from '../Inputs/Birthday';
import Profession from '../Inputs/Profession';
import Country from '../Inputs/Country';
import NumericId from '../Inputs/NumericId';

export default function CustomFields({
    customData,
    mobile,
    customFields,
    professionData,
    countryData,
    confirmInput,
    confirmOption,
    setPopupWarning,
    registerData,
    className,
    userPerfil,
    text
}) {
    const {registerCustomData,setRegisterCustomData} = customData
    const [inputs, setInputs] = useState(showInputs({registerCustomData,...userPerfil}, customFields));

    function AddDataField(input, value, type) {
        const field = type?input.slug+type:input.slug;
        let newData = registerCustomData;
        newData[field] = value
        setRegisterCustomData(newData);
        setInputs(showInputs(newData,customFields))

    }
    function showInputs(newData, customFields) {
        const data = customFields?customFields.filter(input=>{
            const conditionField = input.condicao?input.condicao[0]:""
            const condition = input.condicao?input.condicao[1]:""
            const isCondition = input.condicao&&newData[conditionField]===condition
                                    // newData[conditionField]===condition
            return isCondition||!input.condicao

        }):""
        return data
    }
    function AddDataCheckbox(input, target) {
        if(registerCustomData[input.slug]){
            if(target.checked){
                const newData = [...registerCustomData[input.slug], target.value]
                AddDataField(input,newData)
            }else {
                const newData = registerCustomData[input.slug].filter(option=>{
                    return option!==target.value
                })
                AddDataField(input,newData)
            }
        }else{
            AddDataField(input,[target.value])
        }
    }
    function uploadFile(input, element) {
       //    4080000 ~ 3.9mb
       if(element.files[0]){
            if(element.files[0].size>4080000){
                element.value = ""
                setPopupWarning(text.uploadExcedeLimite)
        }else AddDataField(input, element.files[0])
       }
       
     
    }

    if(inputs.length) return (
        inputs.map((input, index)=>{
                return (
                    input.tipo==="date"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Date  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                            />
                    </label>
                    :input.tipo==="text"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Text  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                            />
                    </label>
                    :input.tipo==="binary"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <File  
                                className={className}
                                input={input}
                                uploadFile={uploadFile}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                                userPerfil={userPerfil}
                                text={text}
                                mobile={mobile}
                            />
                        </label>
                    :input.tipo==="longtext"?
                        <label key={input.slug+index} className="input_fullSize"><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <TextArea  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                            />
                        </label>
                    :input.tipo==="dropdown"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Select  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                registerCustomData={registerCustomData}
                                confirmInput={confirmInput}
                            />
                    </label>
                    :input.tipo==="check"?
                        <label key={input.slug+index} className={input.opcoes.length>3?"input_fullSize":""}  ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Checkbox  
                                className={className}
                                input={input}
                                AddDataCheckbox={AddDataCheckbox}
                                confirmOption={confirmOption}
                                registerCustomData={registerCustomData}
                            />
                    </label>
                    :input.tipo==="radio"?
                        <label key={input.slug+index} className={input.opcoes.length>3?"input_fullSize":""}  ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Radio  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmOption={confirmOption}
                                registerCustomData={registerCustomData}
                            />
                    </label>
                    :input.tipo==="phone"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <PhoneNumber
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                                registerData={registerData}
                                className={className}

                            />
                        </label>
                        :input.tipo==="phonelocal"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <PhoneNumberLocal
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                                registerData={registerData}
                            />
                        </label>
                    :input.tipo==="nascimento"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Birthday
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerData={registerData}
                                text={text}
                            />
                        </label>
                    :input.tipo==="profissao"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Profession  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                professionData={professionData}
                                registerCustomData={registerCustomData}
                                userPerfil={userPerfil}
                            />
                        </label>
                    :input.tipo==="pais"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <Country  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                countryData={countryData}
                                registerCustomData={registerCustomData}
                                userPerfil={userPerfil}
                            />
                    </label>
                    :input.tipo==="idnumerico"?
                        <label key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                            <NumericId  
                                className={className}
                                input={input}
                                AddDataField={AddDataField}
                                confirmInput={confirmInput}
                                registerCustomData={registerCustomData}
                            />
                    </label>
                     :<></>
                
            )
        }) 
    );
    else return <></>
   
}       
        
