import {pt, en} from "../Lang"


export const isAuthenticated = () => localStorage.getItem('AuthorizationNeo') !== null;
export const getToken = () => localStorage.getItem('AuthorizationNeo');
// export const getLang = lang => lang?lang:navigator.language;
export const isEnglish = lang => lang.toLowerCase()==="en-gb"||lang.toLowerCase()==="en"||lang.toLowerCase()==="engb";
export const getText = lang => {
  if(lang){
    if(isEnglish(lang)){
      return en();
    }else{
      return pt();
    }
  }else{
    if(isEnglish(navigator.language)){
      return en();
    }else{
      return pt();
    }
  }

};
export function removeEmptyItens(data) {
  const obj = data
  for (var propName in obj) { 
    if (obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}
export const destroyToken = () => {
  localStorage.removeItem('AuthorizationNeo');
};
export const htmlDecode = input => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}
export const formatArrayForOutlet = (content) => {
  if(content){
  const podcastArray = content.filter((item)=>{
    return item.tipo==="podcast"
  })

  if(podcastArray.length>0){
    const categoryArray = podcastArray.reduce((r, a) => {
      r[a.categoria] = [...r[a.categoria] || [], a];
      return r;
    }, {});
    const keys = Object.keys(categoryArray)
    const categoryArrayArray = keys.map(item=>{
      return {
        id: categoryArray[item][0].id,
        tipo: categoryArray[item][0].tipo,
        titulo: categoryArray[item][0].categoria,
        imagem: categoryArray[item][0].imagem,
        seccionContent: categoryArray[item],
      }
    })
    const noPodcastArray = content.filter((item)=>{
      return item.tipo!=="podcast"
    })

    return [...noPodcastArray, ...categoryArrayArray]

  }else return content}

}
export const formatArray = (content,titulo,type) => {

  if(content){
    const categoryArray = content.reduce((r, a) => {
      r[a.categoria] = [...r[a.categoria] || [], a];
      return r;
    }, {});
    const arrayKeys = Object.keys(categoryArray)
    const categoryArrayArray = arrayKeys.map(item=>{
    
      return {
        id: categoryArray[item][0].id,
        tipo: type,
        titulo: item?item:titulo,
        imagem: categoryArray[item][0].imagem,
        seccionContent: categoryArray[item],
      }
    })
 
    return categoryArrayArray}

}
export const setVideoType = (url) =>{
  if(url){
    if(url.includes("vimeo")) return "vimeo"
    else if(url.includes("yout")) return "youtube"
    else if(url.includes(".mp4")) return "mp4"
    else if(url.includes(".mpd")) return "encripted"
    else return "other"
  }

}
export const formateSeconds = (seconds) => {
  let hour, minute, sec;
  sec = Math.floor(seconds);
  minute = Math.floor(sec / 60);
  sec = sec % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;

  return {
      hour: hour,
      minute: minute,
      seconds: sec
  };
}
export const formateTimer = (formatedSeconds) => {
  const hour = formatedSeconds.hour>0?formatedSeconds.hour+":":""
  const min = addZeroToUnitary(formatedSeconds.minute)+":"
  const sec = addZeroToUnitary(formatedSeconds.seconds)

  return hour+min+sec
  
}
const addZeroToUnitary = (number) => {
  return number<10?"0"+number:number
}
export const isMobile = () =>{
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return  true;
  }
}
export const isIOS = () =>{
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/Android/i.test(userAgent)) {
      return false;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
  }
}
export const isHorizontal = () =>{
  if( window.innerHeight > window.innerWidth){
      return false;
  }else  return true;
}
export const loading = (status) =>{
  const loadingScreen = document.querySelector("#loading_container")
  if(loadingScreen){
    if(status) {
      loadingScreen.style.display = "flex"
    }else loadingScreen.style.display = "none"
  }
}
export const formateTimeAudio = (seconds) => {
  if(!isNaN(seconds)){
    const formatedSeconds = formateSeconds(seconds)
    const hour = formatedSeconds.hour>0?formatedSeconds.hour+":":""
    const min = hour?addZeroToUnitary(formatedSeconds.minute):formatedSeconds.minute+":"
    const sec = addZeroToUnitary(formatedSeconds.seconds)

    return hour+min+sec
  }else return "0:00"
  
}
export const getRandomList = (max) => {
  let array = []

  for (var i=0; i < max; i++) array.push(i)

  return shuffleArray(array)
}
export const objIsEqual = (obj1, obj2) => {
  
  return JSON.stringify(obj1)===JSON.stringify(obj2)
  
}
export const shuffleArray = (array) => {
  
  let m = array.length, t, i;

  while (m) {

    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
  
}
export const addWarning = (el) => el.classList?.add("warning");
export const removeWarning = (el) => el.classList?.remove("warning");
export const convertToDate = (value, lang) => {
  if(isEnglish(lang)){
    const date =
    value.slice(0, 2) +
    "/" +
    value.slice(3, 5) +
    "/" +
    value.slice(-4);

    return  new Date(date);
  }else{
    const date =
    value.slice(3, 5) +
    "/" +
    value.slice(0, 2) +
    "/" +
    value.slice(-4);

    return  new Date(date);
  }
}

export const extractTokenUrl = (pathname) => {
  return pathname.split("/")
  .filter(params => params !== ""&&!params.includes("perfil"))
  .join("/")
}
export const extractParamsUrl = (search) => {

  const array = search.slice(1).split("&")

  if(array.length>0) return {
    mdi_id : array.find(item=>item.includes("mdi_id"))?array.find(item=>item.includes("mdi_id")).split("=")[1]:"",
    lang : array.find(item=>item.includes("lang"))?array.find(item=>item.includes("lang")).split("=")[1]:"",
    logo : array.find(item=>item.includes("logo"))?array.find(item=>item.includes("logo")).split("=")[1]:"true"
  }
}
export const getSecondsFromFormatedTimer = (formatedTime) => {
  const splitedTime = formatedTime.split(":")
  const arraySize = splitedTime.length

  const seconds = splitedTime[arraySize-1]*1
  const minutes = splitedTime[arraySize-2]*1
  
  return seconds+(minutes*60)

}