import React from 'react';

export default function PhoneNumber({
    input,
    AddDataField,
    confirmInput,
    registerCustomData,
    className
}) {
    function phoneMask(value) {
        return value
          .replace(/\D/g, "")
      }
      function phoneDDIMask(value) {
        return value
          .replace(/\D/g, "")
          .replace(/(\d{0})(\d)/, "$1$2") 
      }
   
    return (
        <div className="phone_input">
            <input 
                 name={`${input.nome} DDI`} 
                 type="text"
                 placeholder={`DDI`}
                 maxLength="3"
                 className={className} 
                 onChange={e => AddDataField(input, phoneDDIMask(e.target.value),"-ddi")} 
                 onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
                 value={'+'+registerCustomData[input.slug+"-ddi"]}
                />
            <input 
                 name={input.nome} 
                 type="text"
                 className={className}
                 maxLength="11"
                 placeholder={input.placeholder}
                 onChange={e => AddDataField(input, phoneMask(e.target.value))} 
                 onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
                 value={registerCustomData[input.slug]}
                />
        </div>
    );
    
   
}       
        
