import { useState, useEffect } from 'react';
import {useDispatch} from "react-redux";
import {setPopupData} from "../redux/actions";

export default function usePopUp() {
  const dispatch = useDispatch();
 
  const [popUpMessage, setPopUpMessage] = useState("");
  const [popUpFunction, setPopUpFunction] = useState("");

  useEffect(() => {
    dispatch(setPopupData({text:popUpMessage,function:popUpFunction}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpMessage,popUpFunction])

  return [setPopUpMessage, setPopUpFunction]
}
