import React, { useState, useRef, useContext } from "react";

import { faPhone, faEnvelope,faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { addWarning, removeWarning, destroyToken, removeEmptyItens } from "../utils";
import PopupPerfil from "./popupsPerfil";
import CustomFields from "./CustomFields";
import { buscarEnd, setNewDataProfile, getUserData, getNeosList, } from "../api"
import {userContext} from '../context';

export default function Perfil({
  match,
  mobile,
  logo,
  mdi_id,
  lang,
  countriesPerfil,
  professionsPerfil,
  userPerfil,
  layoutPerfil,
  text,
  }) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iosAgent = userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i )?true:false;
  const history = useHistory();
  const { user } = useContext(userContext)
  const { setUserData } = user


  const joyceLayout = match.params.mdi_id==="229"&&mobile?"joyceLayout":""
 
  const listaGruposComprar = userPerfil?.grupos_pagos;
  const listaGrupos = userPerfil?.grupos
  const tipoForm = layoutPerfil?layoutPerfil.tipo_form_cadastro:1;
  const listContacts = userPerfil?.contatos_neo;
  const customFields = layoutPerfil?.campos_dinamicos;

  const [statusPopup, setStatusPopup] = useState("disable");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupBtn, setPopupBtn] = useState(true);

  const [perfilData, setPerfilData] = useState(userPerfil?userPerfil:{});
  const [registerCustomData,setRegisterCustomData] = useState(userPerfil.campos_dinamicos===Object(userPerfil.campos_dinamicos)?userPerfil.campos_dinamicos:{});
  const [birthdayToShow, setBirthdayToShow] = useState(userPerfil.nascimento);

  const inputSenha = useRef()
  const inputConfirmaSenha = useRef()
  const inputNascimento = useRef()
  const inputProfession = useRef()
  const inputPais = useRef()

    function dateMask(value) {
        return value
          .replace(/\D/g, "") 
          .replace(/(\d{2})(\d)/, "$1/$2") 
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\/\d{4})\d+?$/, "$1"); 
    }
    function ddiMask(value) {
      return value
        .replace(/\D/g, "") 
        .replace(/(\d{0})(\d)/, "$1+$2") 
    }
    function numericMask(value) {
      return value
        .replace(/\D/g, "") 
    }

    function confirmBirthdayDate (target) {
        const today = Date.now()
        const {value} = target
        const day = lang==="en"?value.slice(3, 5):value.slice(0, 2);
        const month = lang==="en"?value.slice(0, 2):value.slice(3, 5);
        const year = value.slice(-4);

        const time = new Date(month+"/"+day+"/"+year);
        
        if(!isNaN(time)&&(((today-time)/31104000000)>=0)){
            setPerfilData(perfilData => {
              const newData = {...perfilData, nascimento:year+"-"+month+"-"+day}
              return newData
            })  
        }else {
            setBirthdayToShow("");
            target.value = ""
        }
        confirmInput(target)
    }
    const showHeader = () => {
      if (!mobile) {
        return (
          <>
            <FontAwesomeIcon
              className="Perfil_headerIcon"
              icon={faUserAlt}
            />
            <p className="popup__title">{text.atualizarPerfil}</p>
          </>
        );
      }else if(layoutPerfil.logo&&logo==="true"){
        return <img className="form__perfilLogo" src={layoutPerfil.logo} alt={`logo ${layoutPerfil.nome}`}></img>
      }
    };
    const confirmarSenha = () => {
      if (perfilData.senha === inputConfirmaSenha.current.value||!perfilData.senha) {
        removeWarning(inputConfirmaSenha.current)
        return;
      }
      addWarning(inputConfirmaSenha.current);
    };
    function isNull(listContacts){
     if (listContacts) {const keysObj = Object.keys(listContacts)
      let nullObj = true

      keysObj.forEach(element => {
          if (listContacts[element]) nullObj = false
      });
    
      return nullObj}
    }
    
    const showContacts = () => {
      if (!isNull(listContacts) && listContacts){
        const contsctsTypes = Object.keys(listContacts)
          
          const arrayContacts = contsctsTypes.map(item => {

            if(listContacts[item]) return {type:item,url:listContacts[item]}
            return undefined
          }).filter(item=>item!==undefined)

        return (
          <div className="perfil_container">
          <h3>{text.contatos}</h3>  
          {arrayContacts.map(item=>{
            if(item.type==="telefone"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={`tel:${item.url}`}>
                  <FontAwesomeIcon className="contact_icon" icon={faPhone} />
                  {item.url}
                </a>
              </p>
            )}else if(item.type==="email"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={`mailto:${item.url}`}>
                  <FontAwesomeIcon className="contact_icon" icon={faEnvelope} />
                  {item.url}
                </a>
              </p>
            )}else if(item.type==="youtube"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={item.url}>
                  <FontAwesomeIcon className="contact_icon" icon={faYoutube} />
                  /{getLastUrlContent(item.url)}
                </a>
              </p>
            )}else if(item.type==="facebook"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"}  href={item.url}>
                  <FontAwesomeIcon className="contact_icon" icon={faFacebook} />
                  /{getLastUrlContent(item.url)}
                </a>
              </p>
            )}else if(item.type==="instagram"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={item.url}>
                  <FontAwesomeIcon className="contact_icon" icon={faInstagram} />
                  /{getLastUrlContent(item.url)}
                </a>
              </p>
            )}else if(item.type==="linkedin"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={item.url}>
                  <FontAwesomeIcon className="contact_icon" icon={faLinkedin} />
                  /{getLastUrlContent(item.url)}
                </a>
              </p>
            )}else if(item.type==="twitter"){return (
              <p key={item.type} className="perfil_contacts">
                <a target={iosAgent?"":"_blank"} href={item.url}>
                  <FontAwesomeIcon className="contact_icon" icon={faTwitter} />
                  /{getLastUrlContent(item.url)}
                </a>
              </p>
            )}else{
            return (
              <p key={item.type} className="perfil_contacts">{item.type} <a target={iosAgent?"":"_blank"} rel="noopener noreferrer" href={item.url}>{item.url}</a></p>
            )}
            
          })}
          </div>
        )
        }
    };
    const getLastUrlContent = (url) => {
      const array = url.split("/")
      return array[array.length-1]?array[array.length-1]:array[array.length-2]

    };
    const showProductsButton = () => {
      if(mdi_id){ 
        return (
        <button type="button" onClick={() => setStatusPopup("enabled")} 
        className={"form__btn--secundary products "+joyceLayout}
        >
          {text.listaProdutos}
        </button>
    
      )}
    };
    async function refreshUserData (userData) {
        try{
          const user = await getUserData()
          const lista = await getNeosList()
          setUserData({...userData,listaNeos:lista})
        }catch(e){ throw Error(e)}
    }

    async function handlePerfilSubmit(event) {
      event.preventDefault()

       
      try{
        if(confirmAllInputs()){
          const newData = formatDataToSend({...removeEmptyItens(perfilData),
            ...registerCustomData,
          })
          
          const resp = await setNewDataProfile(mobile,newData);
          
          if(resp.retorno) {
            // setUserData(resp.dados)
          
            if(mobile) {
              setPopupBtn(true)
              showFinalMessage(text.atualizacaoSucesso)
            }else {
              if (resp.perfil_login) {
                setPopupBtn("logout")
                showFinalMessage(text.atualizacaoLogout)
              }else{
                  setPopupBtn("refresh")
                  showFinalMessage(text.atualizacaoSucesso,resp.dados)
                }
              }
          }
        }else {
          showFinalMessage(text.verifiqueCampos)
          setPopupBtn(true)
        }
      }catch(e){
        showFinalMessage(e.message)
        console.error(e)      
        
      }
    }
    
    const logoutPerfil = () => {
      destroyToken();
      
      if(window.location.pathname==="/home/perfil"){
        history.push(`/`);
      }else { 
        history.push(`/`+ match.params.mdi_id)
        
      }
    };
    const showFinalMessage = (text, dados) => {
      setPopupMessage(text);
      setStatusPopup(dados);
    };
    function setPopupWarning (type){
      setPopupMessage(text)
      setStatusPopup(true)
    }
    function confirmInput (input, confirmValue){
      if(!confirmValue&&input.value===""){
          return addWarning(input)
      }else if(confirmValue&&input.value!==confirmValue) {
          return addWarning(input)
      }
      removeWarning(input)
    }
    function confirmOption(element) {
      const optionsGroup = Array.from(document.querySelectorAll(`[name="${element.name}"]`))
       const checkedOption =  optionsGroup.filter(item=>item.checked)
  
       if(checkedOption.length) return removeWarning(element)
       else return addWarning(element)
     
    }
    async function confirmAdress(target) {
      if(target.value){
          
          const adress = await buscarEnd(perfilData.codigo_pais,target.value)

          if(adress){
            setPerfilData({...perfilData, 
                  cidade:adress.cidade,
                  estado:adress.estado,
                  bairro:adress.bairro,
                  endereco:adress.logradouro,
              })
          }
          
          removeWarning(target);

      }else {
        addWarning(target);
      }
      

    }
    function confirmAllInputs (){
      const inputs = [...Array.from(document.querySelectorAll("input")),...Array.from(document.querySelectorAll("select")),...Array.from(document.querySelectorAll("textarea")),...Array.from(document.querySelectorAll("radio"))]
      inputs.forEach(input=>{
        input.focus() 
        input.blur()
      })
      const warningInputs = inputs.filter(input => input.classList.contains("warning"))
      if(warningInputs.length){
        return false
      }else return true
    }
    function formatDataToSend(data){
      if(isNaN(data.pais))data.pais = data.codigo_pais

      data.mdi_id=layoutPerfil?.mdiId

      const keys = Object.keys(data)

      keys.forEach(item=>{
        if(data[item]===""||data[item]===undefined) delete data[item]
        if (data[item]) if(data[item].mime)delete data[item]
        
      })

      delete data.codigo_pais
      delete data.campos_dinamicos
      delete data.grupos
      delete data.grupos_pagos
      delete data.contatos_neo
      

      return data
    }
  return (
    <div className={"perfil_page "+joyceLayout}>
       <PopupPerfil 
        logoutPerfil={logoutPerfil}
        popupBtn={popupBtn}
        popupMessage={popupMessage} 
        setPopupMessag={setPopupMessage} 
        iosAgent={iosAgent} 
        text={text} 
        status={statusPopup} 
        listaGrupos={listaGrupos} 
        listaGruposComprar={listaGruposComprar} 
        setStatusPopup={setStatusPopup}/>
     
      <form
        id="formPerfil"
        onSubmit={e=>handlePerfilSubmit(e)}
        className={"page__content "+joyceLayout}
      >
        {showHeader()}
        
        <div className={"perfil_inputContainer "+joyceLayout}>
          <label><p>{text.email}<span>*</span></p>
            <input
              placeholder={text.email}
              id="inputEmail"
              type="email"
              onChange={(e) => setPerfilData({...perfilData, email:e.target.value})}
              value={perfilData.email}
              onBlur={e => confirmInput(e.target)} 
              className={"form__input "+joyceLayout}
            />                
          </label>
          
          <label><p>{text.novaSenha}</p>
            <input
              ref={inputSenha}
              placeholder={text.novaSenha}
              id="inputSenha"
              type="password"
              onChange={(e) => setPerfilData({...perfilData, senha:e.target.value})}
              value={perfilData.senha}
              className={"form__input "+joyceLayout}
            />
          </label>
          
          <label><p>{text.confirmacaoSenha}</p>
            <input
              ref={inputConfirmaSenha}
              placeholder={text.confirmacaoSenha}
              id="inputConfirmacaoSenha"
              type="password"
              className={"form__input "+joyceLayout}
              onBlur={(e) => confirmarSenha(e.target.value)}
            />
          </label>
          
          <label><p>{text.nome}<span>*</span></p>
            <input
              placeholder={text.nome}
              id="inputNome"
              type="text"
              onChange={(e) => setPerfilData({...perfilData, nome:e.target.value})}
              value={perfilData.nome}
              onBlur={e => confirmInput(e.target)} 
              className={"form__input "+joyceLayout}
            />
          </label>
          
          <label><p>{text.sobrenome}<span>*</span></p>
            <input
              placeholder={text.sobrenome}
              id="inputSobrenome"
              type="text"
              className={"form__input "+joyceLayout}
              onChange={(e) => setPerfilData({...perfilData, sobrenome:e.target.value})}
              onBlur={e => confirmInput(e.target)} 
              value={perfilData.sobrenome}
            />
          </label>
          
          {tipoForm===1?(<>
        <label><p>{text.nascimento}<span>*</span></p>

          <input
            ref={inputNascimento}
            placeholder={text.nascimento}
            id="inputNascimento"
            type="text"
            className={"form__input "+joyceLayout}
            onChange={e => setBirthdayToShow(dateMask(e.target.value))} 
            onBlur={e=>confirmBirthdayDate(e.target)}
            value={birthdayToShow}
            maxLength="10"
          />
        </label>
        
        <label><p>{text.profissao}</p>
          <select
              ref={inputProfession}
              id="inputProfissao"
              onChange={(e) => setPerfilData({...perfilData, profissao:e.target.value})}
              className={"form__input "+joyceLayout}
            >
              {professionsPerfil?.map((profissao) => (
                <option key={profissao.codigo} selected={perfilData.profissao===profissao.codigo} value={profissao.codigo}>
                  {profissao.valor}
                </option>
              ))}
          </select>
        </label>
        
        <label><p>{text.genero}<span>*</span></p>
          <select
            id="inputSexo"
            onChange={(e) => setPerfilData({...perfilData, sexo:e.target.value})}
            onBlur={e => confirmInput(e.target)} 
            className={"form__input "+joyceLayout}
            list="sexo"
            name="sexo"
          >
            <option/>
            <option selected={userPerfil.sexo==="Masculino"?true:false}  value="Masculino">{text.masculino}</option>
            <option selected={userPerfil.sexo==="Feminino"?true:false} value="Feminino">{text.feminino}</option>
            <option selected={userPerfil.sexo==="Outro"?true:false}  value="Outro">{text.outro}</option>
          </select>
        </label>
        
        <label><p>{text.pais}<span>*</span></p>
          <select
            ref={inputPais}
            id="inputPais"
            onChange={(e) => setPerfilData({...perfilData, pais:e.target.value})}
            onBlur={e => confirmInput(e.target)} 
            className={"form__input "+joyceLayout}
          >
            <option/>
            {countriesPerfil?.map((pais) => (
              <option selected={perfilData.codigo_pais===pais.codigo} key={pais.codigo} value={pais.codigo}>
                {pais.valor}
              </option>
            ))}
          </select>
        </label>
        
        <label><p>{text.cep}<span>*</span></p>
          <input
            placeholder={text.cep}
            onChange={(e) => setPerfilData({...perfilData, cep:e.target.value})}
            onBlur={e => confirmAdress(e.target)} 
            value={perfilData.cep}
            id="inpuCep"
            className={"form__input "+joyceLayout}
            name="cep"
            type="text"
          />
        </label>
        
        <label><p>{text.estado}<span>*</span></p>
          <input
            placeholder={text.estado}
            onChange={(e) => setPerfilData({...perfilData, estado:e.target.value})}
            onBlur={e => confirmInput(e.target)} 
            value={perfilData.estado}
            id="inputEstado"
            className={"form__input "+joyceLayout}
            name="estado"
            type="text"
          />
        </label>
        
        <label><p>{text.cidade}<span>*</span></p>
          <input
            placeholder={text.cidade}
            onChange={(e) => setPerfilData({...perfilData, cidade:e.target.value})}
            onBlur={e => confirmInput(e.target)} 
            value={perfilData.cidade}
            id="inputCidade"
            className={"form__input "+joyceLayout}
            name="cidade"
            type="text"
          />
        </label>
        
        <label><p>{text.endereco}<span>*</span></p>
          <input
            placeholder={text.endereco}
            onChange={(e) => setPerfilData({...perfilData, endereco:e.target.value})}
            value={perfilData.endereco}
            id="inputRua"
            onBlur={e => confirmInput(e.target)} 
            className={"form__input "+joyceLayout}
            name="rua"
            type="text"
          />
        </label>
        
        <label><p>{text.numero}<span>*</span></p>
          <input
            placeholder={text.numero}
            onChange={(e) => setPerfilData({...perfilData, numero:numericMask(e.target.value)})}
            value={perfilData.numero}
            id="inputNumero"
            onBlur={e => confirmInput(e.target)} 
            className={"form__input "+joyceLayout}
            name="numero"
            type="text"
          />
        </label>
        
        <label><p>{text.bairro}<span>*</span></p>
          <input
            placeholder={text.bairro}
            onChange={(e) => setPerfilData({...perfilData, bairro:e.target.value})}
            value={perfilData.bairro}
            id="inputBairro"
            onBlur={e => confirmInput(e.target)} 
            className={"form__input "+joyceLayout}
            name="bairro"
            type="text"
          />
        </label>
        
        <label><p>{text.complemento}</p>
          <input
            placeholder={text.complemento}
            onChange={(e) => setPerfilData({...perfilData, complemento:e.target.value})}
            value={perfilData.complemento}
            id="inputComplemento"
            className={"form__input "+joyceLayout}
            name="complemento"
            type="text"
          />
          </label>
        
          <label><p>{text.celular}<span>*</span></p>
            <div className="perfil__celular">
              <input
                placeholder={text.ddi}
                onChange={(e) => setPerfilData({...perfilData, celular_ddi:ddiMask(e.target.value)})}
                value={perfilData.celular_ddi}
                maxLength="3"
                id="inputDdiCelular"
                name="dddcelular"
                onBlur={e => confirmInput(e.target)} 
                className={"form__input "+joyceLayout}
              />
              <input
                placeholder={text.celular}
                onChange={(e) => setPerfilData({...perfilData, celular_numero:numericMask(e.target.value)})}
                value={perfilData.celular_numero}
                maxLength="11"
                id="inputCelular"
                onBlur={e => confirmInput(e.target)} 
                name="celular"
                className={"form__input "+joyceLayout}
              />

            </div>
          </label>
          <label><p>{text.telefone}</p>
            <div className="perfil__celular">
              <input
                placeholder={text.ddi}
                onChange={(e) => setPerfilData({...perfilData, telefone_ddi:ddiMask(e.target.value)})}
                value={perfilData.telefone_ddi}
                maxLength="3"
                id="inputDdiTelefone"
                name="celular"
                className={"form__input "+joyceLayout}
              />
              <input
                placeholder={text.telefone}
                onChange={(e) => setPerfilData({...perfilData, telefone_numero:numericMask(e.target.value)})}
                value={perfilData.telefone_numero}
                maxLength="11"
                id="inputTelefone"
                name="telefone"
                className={"form__input "+joyceLayout}
              />
            </div>
          </label>

          </>):<></>}
            <CustomFields 
            customFields={customFields}
            customData={{registerCustomData,setRegisterCustomData}}
            confirmInput={confirmInput}
            mobile={mobile}
            countryData={countriesPerfil}
            professionData={professionsPerfil}
            setPopupWarning={setPopupWarning}
            confirmOption={confirmOption}
            registerData={perfilData}
            userPerfil={userPerfil}
            className={"form__input "+joyceLayout}
            text={text}
            />
          </div>
        {showProductsButton()}

        <div className={"popup__response "+joyceLayout}>
          {mobile?"":<button
            onClick={history.goBack}
            type="button"
            className={"form__btn--secundary "+joyceLayout}
            >
            {text.voltar}
          </button>}

          <button type="submit" 
            className={"form__btn--secundary "+joyceLayout}
            >
            {text.enviar}
          </button>
        </div>

        {showContacts()}
    
      </form>
      
    </div>
  );
}
