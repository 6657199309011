import React from 'react';

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Loading() {
   return (
    <div id="loading_container" className="loading_container">
        <FontAwesomeIcon className="loading_icon spin" icon={faCircleNotch} />
    </div>
   )
    
}
 