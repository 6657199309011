import React from "react";
import {htmlDecode} from "../utils"

export default function contentFeaturedImage({data,acessItem}) {
   const type = data?.seccionContent[0]?.url_compra?"buy":data?.tipo
  return (
   <div onClick={()=>acessItem(data.seccionContent[0],type)} className="content_featured">
      {data?
      <header  className="outlet_header">
         <div style={{backgroundImage: "url("+data.imagem+")"}} className="featured_image"></div>

         <div  className="info_featured" >
            <h3>{htmlDecode(data.titulo)}</h3>
            <h4>{htmlDecode(data.seccionContent[0].titulo)}</h4>
            <p>{htmlDecode(data.seccionContent[0].descricao)}</p>
         </div>
      </header>:<></>}
      <div className="featured_filter"></div>
   </div>
   ) 
  
}
