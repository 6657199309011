import React,{ useEffect , useState } from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentSlider from "../components/contentSlider";
import {htmlDecode} from '../utils';

export default function Seccion({data,text,acessItem}) {
  const id = "slider_"+data.id
  
  const [elList, setElList] = useState(null)

  useEffect(() => {
    
    const el = document.getElementById(id)
    setElList(el)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <li className="content_session " >
       <h3 className="content_title">{htmlDecode(data.titulo)}</h3>
       <div className="seccion_conainer"> 
        <div id={id} className="content_slider">
          <ul  className="content_slider-list">
          {data.seccionContent?.map(item=>{
            return (
              <li key={item.id} onClick={()=> acessItem(item,item.url_compra?"buy":data.tipo)} className="content_item">
                <img alt={item.titulo}  className="content_img" src={item.imagem} />        
                {item.url_compra?<div className="content_containerBuy">
                  <FontAwesomeIcon className="content_iconBuy" icon={faShoppingCart}/>
                  <div className="content_textBuy">{text.comprar}</div>
                </div>:<></>}
              </li>
            )
          })} 
          </ul>
        </div>
        <ContentSlider elList={elList}/>
      </div>
    </li>
   ) 
  
}
