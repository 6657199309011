const INITIAL_STATE = {
    popupData: false,
    neoListToShow: [],
    searchInput: "",
}

function reducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case "SET_SEARCH":
            
            return {
                ...state,
                searchInput: action.value
        }

        case "SET_NAVIGATION_SETUP":
            
            return {
                ...state,
                navigationSetup: action.value
        }

        case "SET_POPUPDATA":
            
            return {
                ...state,
                popupData: action.value
            }
    
        default:
            return state
    }
    
}

export default reducer;