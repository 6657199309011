
import { useLayoutEffect, useState } from 'react';

export default function useWindowResize() {
  const [size, setSize] = useState();
  useLayoutEffect(() => {

    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
