import React from 'react';
import useWindowResize from '../hooks/useWindowResize';
import { faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ContentSlider({elList}) {

  useWindowResize();
 
  const slideLeft = () => {
    const options = {
      left: elList.scrollLeft-200,
      behavior: 'smooth'
    }
    elList.scroll(options)
  }

  const slideRight = () => {
    const options = {
      left: elList.scrollLeft+200,
      behavior: 'smooth'
    }
    elList.scroll(options)
  }

  if(elList?.firstChild.clientWidth>elList?.clientWidth) return(
    <div className="Slider_container">
      <button className="slider_button" onClick={()=>slideLeft()}>
        <FontAwesomeIcon className="slider_icon" icon={faChevronLeft} />
      </button>
      <button className="slider_button" onClick={()=>slideRight()}>
        <FontAwesomeIcon className="slider_icon" icon={faChevronRight} />
      </button>
    </div>
  )
  else return <></>
}
