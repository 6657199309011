import React from 'react';

export default function Country({
    input,
    AddDataField,
    confirmInput,
    countryData,
    className,
    registerCustomData,
    userPerfil
}) {

    const country = registerCustomData.pais?registerCustomData.pais:userPerfil.codigo_pais
    return (
        <select 
            name={input.nome} 
            placeholder={input.placeholder}
            onChange={e => AddDataField(input, e.target.value)} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
            className={className}
        >
            <option value="">{input.nome}</option>
            {countryData?.map(option=>{
                return <option key={option.codigo} selected={country?.toString()===option.codigo} value={option.codigo}>{option.valor}</option>
            })}
        </select>
    );
    
   
}       
