import React, { useState, useEffect } from "react";

import { getValidation, getUserData, getLayout, getprofissoes, getPaises } from "../api";
import { extractTokenUrl, extractParamsUrl, getText } from "../utils";

import NeoLayout from '../components/neoLayout';
import Perfil from "../components/perfil";


export default function PerfilMobile(props) {

  const token = extractTokenUrl(props.location.pathname)
  
  const {mdi_id, lang , logo} = extractParamsUrl(props.location?.search)

  const [valid, setValid] = useState(null)
  const [countriesPerfil, setCountriesPerfil] = useState(null)
  const [professionsPerfil, setProfessionsPerfil] = useState(null)
  const [userPerfil, setUserPerfil] = useState(null)
  const [langPerfil, setLangPerfil] = useState(null)
  const [layoutPerfil, setLayoutPerfil] = useState(null)
  const [text, setText] = useState({})

  useEffect(() => {
    const validadeToken = async (token) =>{
      try{
        await getValidation(token)
        setData()
        localStorage.removeItem('AuthorizationNeo')
      }catch(e){
        console.error(e)
        setValid(false)
      }
    }
    validadeToken(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  async function setData() {
    let getLang
    let slug
    if(mdi_id){
      const layoutData = await getLayout(mdi_id)
      slug = layoutData.slug
      if(layoutData){
        layoutData["campos_dinamicos"] = setCustomPerfilHardCode(layoutData)
      }
      setLayoutPerfil(layoutData)
      if(!lang) getLang = layoutData.idioma
    }
    else if(lang) {
      getLang = lang
      if(getLang.toLowerCase().includes('en'))slug = "marc"
      else slug = "mastercase"
    } 
    else {
      getLang = navigator.language || navigator.userLanguage
      if(getLang.toLowerCase().includes('en'))slug = "marc"
      else slug = "mastercase"
    }
    setLangPerfil(getLang)
    setText(getText(getLang))
  
    setCountriesPerfil(await getPaises(getLang, slug))
    setProfessionsPerfil(await getprofissoes(getLang, slug))
    setUserPerfil(await getUserData(token, mdi_id))
    setValid(true)
  }
    
  
  function setCustomPerfilHardCode(layoutPerfil) {
    const mdi_id = layoutPerfil.mdiId

    if (mdi_id==="292") return [
      {
          nome: "Profissão",
          obrigatorio: true,
          placeholder: "Profissão",
          slug: "profissao",
          tipo: "profissao",
        },{
          nome: "CRM",
          obrigatorio: true,
          condicao:["profissao","medico"],
          placeholder: "CRM",
          slug: "numero-crm",
          tipo: "idnumerico",
        }
    ]
    else if (mdi_id==="286") return [
        {
          nome: "Profissão",
          obrigatorio: true,
          placeholder: "Profissão",
          slug: "profissao",
          tipo: "profissao",
        },{
          nome: "CRM",
          obrigatorio: true,
          condicao:["profissao","medico"],
          placeholder: "CRM",
          slug: "numero-crm",
          tipo: "idnumerico",
        },{
          nome: "Carteira de estudante",
          obrigatorio: true,
          condicao:["profissao","academico"],
          placeholder: "Carteira de Estudante",
          slug: "carteira-profissional-estudante",
          tipo: "binary",
        },{
          nome: "Carteira profissional",
          obrigatorio: true,
          condicao:["profissao","profissional-saude"],
          placeholder: "Carteira de Estudante",
          slug: "carteira-profissional-estudante",
          tipo: "binary",
        }
    ]
    else if (mdi_id==="229"){
      return [
        {
          nome: "Nascimento",
          obrigatorio: true,
          placeholder: "Nascimento",
          slug: "nascimento",
          tipo: "nascimento",
        },{
          nome: "Sexo",
          obrigatorio: true,
          opcoes: [
            {codigo: "Feminino", valor: "Feminino"},
            {codigo: "Masculino", valor: "Masculino"},
            {codigo: "Outro", valor: "Outro"},
          ],
          placeholder: "Sexo",
          slug: "sexo",
          tipo: "radio",
        },{
          nome: "País",
          obrigatorio: true,
          placeholder: "Carteira de Estudante",
          slug: "pais",
          tipo: "pais",
        },{
          nome: "Celular",
          obrigatorio: true,
          placeholder: "Celular",
          slug: "celular",
          tipo: "telefone",
        }
    ]}

    else return layoutPerfil.campos_dinamicos;
  }
  
  return (
    <div className={mdi_id?"mobile__body":""}>
      {layoutPerfil?<NeoLayout layout={layoutPerfil.campos_dinamicos} />:<></>}
      {valid?<Perfil {...props} 
      logo={logo} 
      mdi_id={mdi_id} 
      mobile={token} 
      lang={langPerfil} 
      countriesPerfil={countriesPerfil} 
      professionsPerfil={professionsPerfil} 
      userPerfil={userPerfil}
      layoutPerfil={layoutPerfil}
      text={text}
      />:<></>}
    </div>
  );
}