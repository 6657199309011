import React from 'react';
import {getToken} from '../../utils'

export default function File({
    input,
    uploadFile,
    confirmInput,
    className,
    mobile,
    userPerfil
}) {
    // warning, the file token access does not work if you have more than one account oppened. You may use more browsers to test more accounts
    const token = mobile?mobile:getToken()
    //false = !!userPerfil?.campos_dinamicos?.[input.slug].mime
    //false = userPerfil?.campos_dinamicos?.[input.slug].mime===0
    //false = userPerfil?.campos_dinamicos?.[input.slug].mime?.length===0
    //true = userPerfil?.campos_dinamicos?.[input.slug].mime!==0
    //true = userPerfil?.campos_dinamicos?.[input.slug].mime?.length!==0
    
    const inputFile = userPerfil?.campos_dinamicos?.[input.slug].mime===undefined

    return (
        <div className="file__input">
        <input 
            name={input.nome}
            type="file" 
            className={className}
            placeholder={input.placeholder}
            onBlur={e => input.obrigatorio||inputFile?confirmInput(e.target):{}} 
            accept="image/png, image/jpeg, application/pdf"
            onChange={(e)=>uploadFile(input, e.target)}
        />

        {userPerfil?.campos_dinamicos?.[input.slug] && (
           
           userPerfil?.campos_dinamicos?.[input.slug].mime && (<a href={userPerfil?.campos_dinamicos?.[input.slug].link+"&Authorization="+token} 
               rel="noopener noreferrer" target="_blank" className="file__a">
                    Visualizar arquivo
           </a>)
        )} 
        
        </div>
    );
}       
        
