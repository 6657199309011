import React from 'react';

export default function Text({
    input,
    AddDataField,
    confirmInput,
    registerCustomData,
    className
}) {
   
    return (
        <input 
            name={input.nome} 
            type="text"
            placeholder={input.placeholder}
            className={className}
            onChange={e => AddDataField(input, e.target.value)} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
            value={registerCustomData[input.slug]}
        />
    );
    
   
}       
        
