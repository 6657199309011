import React,{useRef} from 'react';

import Navegacao from './navegacao'
import Logo from './parts/logo'
import {useHistory} from 'react-router-dom'
import {useSelector} from "react-redux";

export default function Header() {
    const {navigationSetup} = useSelector(state => state);
    const history = useHistory();
    const sendHome = () =>{
        history.push("/");
    }
    const hideLogo = (status) =>{
        if(status)logoElement.current.classList.add("vanish_element")
        else logoElement.current.classList.remove("vanish_element")
    }
    const logoElement = useRef(null)

    return ( 
        <header className="app_header" > 
            
            <div ref={logoElement} id="logo" onClick={sendHome}  className="login__logo" >
                {navigationSetup?.logo? 
                    navigationSetup.logo===true?<Logo  color1="#ffd330" color2="#fff"/>:
                    <img className="form__perfilLogo" src={navigationSetup.logo} alt={`logo `}></img>
                :<></>}
            </div>

            {navigationSetup?.menu?(
                <Navegacao hideLogo={hideLogo} setup={navigationSetup} />):<></>
            }
        </header>
    );

}
 