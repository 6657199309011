export const pt = () => {
  return {
    menu: "MENU",
    changeFile: "Alterar Arquivo",
    home: "HOME",
    perfil: "PERFIL",
    logout: "SAIR",
    email: "E-mail",
    senha: "Senha",
    entrar: "Entrar",
    genero: "Gênero",
    esqueceuSenha: "Esqueceu a Senha?",
    ou: "ou",
    criarNovaConta: "Criar nova Conta",
    voltar: "Voltar",
    enviar: "Enviar",
    confirmacaoEsqueceuSenha:
      "Enviamos para seu email uma mensagem com a recuperação de senha.",
    erroCarregar: "Erro ao carregar.",
    neoAdicionado: "Neo Adicionado com Sucesso!",
    insiraCodigo: "Insira o código para adicionar um novo Neo.",
    novaSenha: "Nova senha",
    confirmacaoSenha: "Confirmação de nova senha",
    nome: "Nome",
    sobrenome: "Sobrenome",
    nascimento: "Nascimento DD/MM/AAAA",
    profissao: "Profissão",
    atualizacaoLogout:
      "Suas informações de login foram alteradas com sucesso, por favor faça login novamente.",
    feminino: "Feminino",
    masculino: "Masculino",
    outro: "Outro",
    pais: "País",
    cep: "CEP",
    estado: "Estado",
    cidade: "Cidade",
    endereco: "Endereço",
    numero: "Numero",
    bairro: "Bairro",
    complemento: "Complemento",
    telefone: "Telefone",
    ddi: "DDI",
    celular: "Celular",
    atualizarPerfil: "Atualizar dados do Perfil",
    atualizacaoSucesso: "Atualização Realizada com Sucesso!",
    verifiqueCampos: "Por favor, verifique os campos em vermelho.",
    semValidacao: "Seu token não é válido, desconecte e acesse novamente.",
    verifiquePais: "Por favor, verifique os campos em vermelho.",
    semResultado: "Nenhum Resultado",
    certezaSair: "Tem certeza que deseja sair do Neo?",
    cancelar: "Cancelar",
    listaProdutos: "Ver lista de produtos/assinaturas adquiridas",
    semListaProdutos: "Nenhum produto ou assinatura encontrada.",
    assineProdutos: "Assine agora:",
    seusProdutos: "Produtos adquiridos:",
    contatos: "Contato/Suporte:",
    erroSolicitacao: "Erro na solicitação, por favor tente novamente mais tarde.",
    comprar: "Comprar",
    mdi_idInvalido: "URL inválida. Entre em contato com o Suporte e solicite novo endereço.",
    mensagemEnviada: "Mensagem enviada.",
    
  };
};
export const en = () => {
  return {
    menu: "MENU",
    changeFile: "Change File",
    home: "HOME",
    perfil: "ACCOUNT",
    logout: "LOGOUT",
    email: "E-mail",
    senha: "Password",
    entrar: "Login",
    genero: "Gender",
    esqueceuSenha: "Forgot your Password?",
    ou: "or",
    criarNovaConta: "Make a new Account",
    voltar: "Back",
    enviar: "Send",
    confirmacaoEsqueceuSenha:
      "We sent a message to your email adress with your retrived data.",
    erroCarregar: "Error loading your data.",
    neoAdicionado: "Your new Neo is avaliable now!",
    insiraCodigo: "Insert your confirmation code for to add a new Neo",
    novaSenha: "New Password",
    confirmacaoSenha: "Confirm New Password",
    nome: "Name",
    sobrenome: "Surname",
    nascimento: "Birthday MM/DD/AAAA",
    profissao: "Profession",
    atualizacaoLogout: "Credentials updated. Please login again.",
    feminino: "Female",
    masculino: "Male",
    outro: "Other",
    pais: "Country",
    cep: "ZIP Code",
    estado: "State",
    cidade: "City",
    endereco: "Adress",
    numero: "Number",
    bairro: "neighborhood",
    complemento: "Complement",
    telefone: "Phone Number",
    ddi: "DDI",
    celular: "Mobile Phone Number",
    atualizarPerfil: "Update Account Data",
    atualizacaoSucesso: "Your data is now Updated",
    verifiqueCampos: "Please, check your information on the red fields.",
    semValidacao: "Invalid token, please desconect and try again.",
    verifiquePais: "Please, check your information on the red fields.",
    semResultado: "No itens",
    certezaSair: "You are exiting your Neo, are you sure?",
    cancelar: "Cancel",
    listaProdutos: "See list of products/subscriptions acquired",
    semListaProdutos: "No products or subscriptions.",
    assineProdutos: "Subscribe now:",
    seusProdutos: "Acquired products:",
    contatos: "Contact/Support:",
    erroContato: "Server error, please try again later.",
    comprar: "Buy",
    mdi_idInvalido: "invalid URL. Contact the suport center and ask for a new Link.",
    mensagemEnviada: "Message sent.",

  };
};
