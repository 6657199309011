import React from "react";

const SVG = ({

  width = "100%",
  className = "",
  color1 = "#FE7917",
  color2 = "#ffffff",
}) => (
<svg 
  version="1.1"
  xmlns="http://www.w3.org/2000/svg" 
  xmlnsXlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  className={className}
  width={width}
  viewBox="0 0 483.835 165.471" 
  enableBackground="new 0 0 483.835 165.471" 
  xmlSpace="preserve" >
<g id="Guides_For_Artboard"></g>
<g id="Layer_1">
  <path id="logo__cor1" fillRule="evenodd" clipRule="evenodd" fill={color1} d="M399.451,132.087c24.06,0,43.683-19.623,43.683-43.682c0-24.06-19.623-43.683-43.683-43.683c-24.059,0-43.683,19.623-43.683,43.683C355.768,112.464,375.392,132.087,399.451,132.087z"></path>
  <path id="logo__cor2" fillRule="evenodd" clipRule="evenodd" fill={color2} d="M154.123,88.405c-0.008-42.558-34.508-77.057-77.065-77.057C34.499,11.348,0,45.847,0,88.405v77.057h33.848l-0.007-77.057c-0.007-23.872,19.345-43.224,43.217-43.224c23.872,0,43.223,19.352,43.224,43.224l0.001,77.057h33.849L154.123,88.405L154.123,88.405z M237.797,11.348c-42.558,0.008-77.057,34.508-77.057,77.065s34.499,77.058,77.057,77.058h77.057v-33.849l-77.057,0.007c-23.872,0.008-43.224-19.345-43.224-43.216s19.352-43.223,43.224-43.224l77.057-0.001V11.339L237.797,11.348L237.797,11.348z M399.504,11.348c42.557,0,77.057,34.499,77.057,77.056c0,42.558-34.5,77.058-77.057,77.058c-36.743,0-67.476-25.718-75.188-60.133h-106.51V71.48h106.51C332.028,37.065,362.76,11.348,399.504,11.348L399.504,11.348z M399.504,45.181c-23.872,0-43.224,19.352-43.224,43.223c0,23.873,19.352,43.224,43.224,43.224c23.871,0,43.224-19.352,43.224-43.224C442.728,64.533,423.375,45.181,399.504,45.181z"></path>
  <path id="logo__cor2" fill={color2} d="M468.767,6.891v4.397h1.977c1.125,0,1.947-0.173,2.471-0.514c0.518-0.343,0.773-0.885,0.773-1.626c0-0.775-0.268-1.344-0.807-1.713c-0.543-0.36-1.4-0.544-2.57-0.544H468.767L468.767,6.891z M466.034,5.163h5.377c1.943,0,3.42,0.321,4.42,0.959s1.5,1.581,1.5,2.829c0,1.022-0.27,1.867-0.809,2.533c-0.535,0.667-1.307,1.111-2.307,1.322l3.115,6.333h-3.35l-2.775-5.844h-2.178v5.844h-2.994V5.163L466.034,5.163z"></path>
  <path id="logo__strok" fill="none" stroke={color2}strokeWidth="1.8045" strokeMiterlimit="10" d="M471.669,0.902c1.551,0,3.014,0.283,4.383,0.851c1.375,0.569,2.598,1.405,3.67,2.509c1.049,1.052,1.842,2.25,2.391,3.593c0.545,1.343,0.82,2.77,0.82,4.284c0,1.549-0.281,3.001-0.848,4.361c-0.557,1.358-1.377,2.556-2.451,3.604c-1.094,1.062-2.328,1.876-3.695,2.447c-1.365,0.565-2.793,0.849-4.27,0.849c-1.494,0-2.926-0.29-4.301-0.875c-1.367-0.582-2.59-1.421-3.672-2.515c-1.061-1.074-1.871-2.288-2.428-3.63c-0.557-1.348-0.836-2.764-0.836-4.242c0-1.494,0.291-2.929,0.875-4.321c0.582-1.39,1.42-2.627,2.516-3.723c1.053-1.057,2.24-1.851,3.564-2.389C468.708,1.171,470.134,0.902,471.669,0.902z"></path>
  <radialGradient id="SVGID_1_" cx="399.4507" cy="88.4043" r="43.6826" gradientUnits="userSpaceOnUse">
    <stop offset="0" stopColor="#000000" stopOpacity="0%"></stop>                    
  </radialGradient>
  <path fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_1_)" d="M399.451,132.087c24.06,0,43.683-19.623,43.683-43.682c0-24.06-19.623-43.683-43.683-43.683c-24.059,0-43.683,19.623-43.683,43.683C355.768,112.464,375.392,132.087,399.451,132.087z"></path>
  <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="395.4507" y1="97.1616" x2="395.4507" y2="46.7222">
    <stop offset="0" stopColor="#ffffff" stopOpacity="0%"></stop>
    <stop offset="1" stopColor="#ffffff" stopOpacity="30%"></stop>
  </linearGradient>            
  <path fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_2_)" d="M431.851,67.292c-11.401-37.174-82.859-22.027-72.425,29.87C386.841,65.519,438.973,90.51,431.851,67.292z"></path>
</g>
</svg>
);

export default SVG;