import React,{useEffect,useState, useContext} from 'react'
import {useHistory} from 'react-router-dom';
import usePopup from '../../hooks/usePopUp';

import {contentDataContext, audioDataContext} from '../../context';

import {htmlDecode} from "../../utils"
import {getEncriptedVideoData} from "../../api"

import Album from '../../components/album'
import Playlist from '../../components/playList'
import Iframe from '../../components/iframe';
import VideoPlayer from '../../components/videoPlayer'

export default function Item({match,text}) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const mdi_id = match.params.mdi_id
  const view = match.params.view
  const itemType = match.params.itemType
  const type = match.params.type
  const history = useHistory();
  
  const [setPopUpMessage, setPopUpFunction] = usePopup(null);

  const { item } = useContext(contentDataContext)
  const { itemContext } = item

  const { audio, song } = useContext(audioDataContext)
  const { audioContext, setAudioContext } = audio
  const { setCurrentSongContext } = song

  const [urlVideo, setUrlVideo] = useState("")
  const [encriptedData, setEncriptedData] = useState(null)

  useEffect(() => {
    if(!itemContext||itemContext.length===0) history.replace(`/${view}/${mdi_id}`)
    else if(itemType==="video"){
      if(itemContext.url_fairplay) prepareEncriptedUrl(itemContext)
      else setUrlVideo(itemContext.url)
    }else if(itemType==="podcast"&&!audioContext){
      setAudioContext(itemContext)
      setCurrentSongContext(0)
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prepareEncriptedUrl = async item => {
    try{
      const type = isSafari?"fairplay":"widevine"
      const data = await getEncriptedVideoData(mdi_id,item.id,type)
      setUrlVideo(data.url+data.signed_params)
      setEncriptedData(data)
    }catch(e){
      setPopUpFunction(()=>history.goBack())
      setPopUpMessage(text.erroSolicitacao)
      console.error(e)
    }
    
  }

  if(itemType==="video") return(
    <div className="content_video">
    <VideoPlayer  
      url={urlVideo} 
      title={itemContext?.titulo}  
      description={itemContext?.descricao} 
      encriptedData={encriptedData} 
    />
  </div>
  ) 
  if(itemType==="buy") return(
    <div className="neo_content">
      <Iframe src={itemContext?.url_compra} width="100%" height="100%" />
    </div>
  ) 
  if(type==="conteudolivre"||type==="elive") return(
    <div className="neo_content">
      <Iframe src={itemContext?.url} width="100%" height="100%" />
    </div>
  ) 
  if(itemType==="documento") {
    const fileUrl = itemContext?.url
    if(fileUrl?.slice(-3)!=="pdf"){
      const url = itemContext?`https://docs.google.com/gview?url=${fileUrl}&embedded=true`:""
      return(
        <div className="neo_content">
          <div className="file_content">
            <Iframe src={url} className={"ppt"} width="100%" height="100%" />
            </div>
        </div>) 
    }else {
      return(
        <div className="neo_content">
          <div className="file_content">
            <Iframe src={fileUrl}  width="100%" height="100%" />
            </div>
        </div>
      )
    }
  }

  if(itemType==="podcast") return(
    <div className="content_audio">
      <div className="audioplayer_top">
      <div style={{backgroundImage:`url(${itemContext?.imagem})`}} className="audioplayer_topo">
      </div>
      <div className="audioplayer_grandient">
        <Album img={itemContext?.imagem} title={htmlDecode(itemContext?.titulo)} description={htmlDecode(itemContext?.descricao)} />
      </div>
      </div>
      <Playlist playlist={itemContext?.seccionContent}  />
    </div>
  ) 
  else return <></>


}
