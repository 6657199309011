import React,{useEffect} from "react";
import {loading} from "../utils";


export default function Iframe({src,height,width}) {

  useEffect(() => {
    loading(true)
  }, [])

  return(
    <>
      <iframe title="iframe" onLoad={()=>loading(false)}  id="iframeNeo" src={src} height={height} width={width} allow="autoplay *; encrypted-media *;camera *;microphone *;fullscreen *;speaker *;display-capture *"/>  
    </>
  )

}
