export function setPopup (value) {
    return {
         type: "SET_POPUPSTATUS",
         value:value
     }
 }
 export function setPopupData (value) {
    return {
         type: "SET_POPUPDATA",
         value:value
     }
 }
 export function setSearch (value) {
    return {
         type: "SET_SEARCH",
         value:value
     }
 }
 export function setNavigationSetup (value) {
    return {
         type: "SET_NAVIGATION_SETUP",
         value:value
     }
}
