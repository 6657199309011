import { useState, useEffect } from 'react';
import {useDispatch} from "react-redux";
import {setNavigationSetup} from "../redux/actions";

export default function useNavigationSetup() {
  const dispatch = useDispatch();
 
  const [setup, setSetup] = useState(null);
  useEffect(() => {
    dispatch(setNavigationSetup(setup));
  }, [setup,dispatch])

  return setSetup
}
