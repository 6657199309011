import React, {useEffect, useState, useRef} from 'react';
import ShakaPlayer from 'shaka-player-react'
import './style.scss';
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player'
import {formateSeconds,formateTimer,isMobile,isIOS, isHorizontal,setVideoType,htmlDecode,getSecondsFromFormatedTimer} from '../../utils'
import { faCircleNotch, faPlayCircle, faPlay, faPauseCircle, faPause, faVolumeUp, faVolumeMute, faCompress, faExpand} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Player({url,sync,title,description,opening,encriptedData}) {
    const history = useHistory();
    const type = setVideoType(url)
    const [horizontalView, setHorizontalView] = useState(null)

    const [onCOntroll, setOnControll] = useState(null)
    const [timerControll, setTimerControll] = useState(null)

    const [encriptedUiPlay, setEncriptedUiPlay] = useState(null)
    const [encriptedUiMute, setEncriptedUiMute] = useState(null)

    const [playing, setPlaying] = useState(false)
    const [fullScreen, setFullScreen] = useState(null)
    const [volume, setVolume] = useState(50)

    const [timerVideo, setTimerVideo] = useState(null)
    const [timePassed, setTimePassed] = useState(0)
    const [timeTotal, setTimeTotal] = useState(0)
    
    const interfaceRef = useRef()
    const screenRef = useRef()
    const controllRef = useRef()
    const progressRef = useRef()
    const timerRef = useRef()
    const volumeRef = useRef()
    const playerRef = useRef()
    const loadingRef = useRef()
    const vimeoPlayerRef = useRef()
    const encriptedPlayerRef = useRef()

    const mobile = isMobile()
    const IOS = isIOS()

    useEffect(() => {
        if(url){
            createPlayer(type)
            setHorizontalView(isHorizontal())
            if(type==="youtube")backoutScreen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        addListenners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressRef,volumeRef])

    useEffect(() => {
        if(encriptedData&&encriptedPlayerRef&&encriptedPlayerRef.current){
            setEncriptedUiPlay(document.querySelector(".shaka-small-play-button"))
            setEncriptedUiMute(document.querySelector(".shaka-mute-button"))

            const encriptedProgress = document.querySelector(".shaka-seek-bar")
            const encriptedVolume = document.querySelector(".shaka-volume-bar")

            progressRef.current.appendChild(encriptedProgress)
            volumeRef.current.appendChild(encriptedVolume)


            const {player} = encriptedPlayerRef.current;

            player.configure({
                drm: {
                  servers: {
                    "com.widevine.alpha": encriptedData.drm
                  }
                }
              })
            player.addEventListener('buffering', e=>{
                if(e.buffering)loadingOn()
                else {
                    loadingOff()
                    setTimeTotal(getSecondsFromFormatedTimer(document.querySelector(".shaka-current-time").innerHTML.split("/")[1]))
                }
            })
            player.addEventListener('error', (e) =>{
                console.log(e.details)
            })
            
            encriptedProgress.addEventListener('click', function(event) {
                const click = event.offsetX;
                const total = event.target.clientWidth;
                videoSeek(click,total)
                
            })
            
            player.getNetworkingEngine().registerRequestFilter(function(type, request) {
                const hasParams = request.uris[0].includes("Key-Pair-Id")
                if(request.method==="GET"&&!hasParams) request.uris[0] += encriptedData.signed_params
              });
            loadEncriptedVideo(player)


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encriptedData])

    useEffect(() => {
        if(encriptedData){
            if(encriptedUiPlay)encriptedUiPlay.click()
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing])


    
    const loadEncriptedVideo = async (player) => {
        try {
            await player.load(url);
          } catch (e) {
            console.log(e);
          }
    }
    const backoutScreen = (status) => {
        if(type==="youtube"){
            if(status){
                playerRef.current.firstChild.style.opacity = 0
                playerRef.current.firstChild.style.transition = ".5s"
            }
            else {
                playerRef.current.firstChild.style.opacity = 1
                
            }

        }
    }
    const setLiveOrNot = () => {
        loadingOff()
        if(!vimeoPlayerRef.current.getDuration())setPlaying(true)
    }
    const createPlayer = (type) =>{
        const id = url.split("/")[url.split("/").length-1]
        
        if(type==="youtube"){
            playerRef.current.style.backgroundImage = `url('http://i3.ytimg.com/vi/${id}/maxresdefault.jpg')`
        }

    }
    const loadingOff = (remove) =>{

        if(remove)loadingRef.current.remove()
        else loadingRef.current.style.display = "none"
               
    }
    const loadingOn = () =>{
        
        setPlaying(playing=>{
            if(loadingRef)loadingRef.current.style.display = "flex"
            return playing
        })
    }
    function addListenners() {
        if(type==="other") return

        if(!sync&&progressRef&&progressRef.current){
            progressRef.current.addEventListener('click', function(event) {
                const click = event.offsetX;
                const total = event.target.clientWidth;
                videoSeek(click,total)
                
            })
        }
       
        if(volumeRef&&volumeRef.current){
            volumeRef.current.addEventListener('click', function(event) {
                const click = event.offsetX;
                const total = event.target.clientWidth;
                volumeSeek(click,total)
                
            })
        }

        if(mobile&&!IOS){
            window.screen.orientation.addEventListener('change', function() {
                
                if(!isHorizontal()){
                    if(!document.fullscreenElement){enableFullscreen()};
                    setHorizontalView(true)

                }else{
                    if(document.fullscreenElement){disableFullscreen()};
                    setHorizontalView(false)

                }
            });
        }
        document.addEventListener('keydown', function(event) {
            if(event.key==="Escape") history.goBack()
            
        });
    }
    const endedRoutine = () =>{
        if(opening){
            opening(true)
            vimeoPlayerRef.current.seekTo(0)
            setTimePassed(0)
        }
        
    }
    const setControllOn = () =>{
        if(screenRef.current)screenRef.current.classList.remove("mouse_off")
        if(controllRef.current)controllRef.current.classList.remove("controll_off")
    }
    const setControllOff = () =>{
        if(controllRef.current)controllRef.current.classList.add("controll_off")
        if(screenRef.current)screenRef.current.classList.add("mouse_off")
    }
    const setControllTimer = () =>{
        if(interfaceRef.current) interfaceRef.current.setAttribute('onmousemove', '');
        clearTimeout(timerControll)
        setControllOn()

        if(playing){
            setTimerControll(setTimeout(() => {
                if(!onCOntroll){
                    if(interfaceRef.current)interfaceRef.current.setAttribute('onmousemove', ()=>setControllTimer());
                    if(playing)setControllOff()
                }
                
            }, 3000))
        }
    }
    const videoSeek = (click,total) =>{
            
        setTimeTotal(timeTotal=>{
            const newTime = Math.floor((timeTotal*click)/total);
            if(!encriptedData) vimeoPlayerRef.current.seekTo(newTime)
            setTimePassed(newTime)
            return timeTotal
            }
        )
       
    }
    const volumeSeek = (click,total) =>{
        setTimeTotal(timeTotal=>{
            const newVolume = Math.floor((100*click)/total);
            setVolume(newVolume)
            return timeTotal
            }
        )
    }
    const setPlay = () =>{
        videoTimer()
        setPlaying(true)
    }
    const setPause = () =>{
        setPlaying(false)
        clearTimeout(timerVideo)
        clearTimeout(timerControll)
        setControllOn()
    }
    const playPause = () =>{
        if(playing)setPause()
        else setPlay()
    }
    const screenPlayPause = () =>{
        if(mobile&&playing) setControllTimer()
        else playPause()
    }
    const munteUnmut = () =>{
        if(encriptedUiMute)encriptedUiMute.click()

        if(volume){
            setVolume(0)
        } else {
            setVolume(50)
        }
    }
    const fullScreenSmallScreen = () =>{
        if(fullScreen){
            setFullScreen(false)
            disableFullscreen()
        }
        else {
            setFullScreen(true)
            enableFullscreen()
        }
    }
    const videoTimer = () =>{
        clearTimeout(timerVideo)
       
        setTimerVideo(setInterval(() => {

            setTimePassed(seconds=>{
                if (seconds<timeTotal) return seconds+1
                else return seconds
            })

        }, 1000))
        
    }
    const showProgressBar = () =>{
        return timePassed*100/timeTotal+"%"
    }
    const showVolumeBar = () =>{
        return volume+"%"
    }
    const enableFullscreen = () =>{
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }
    }
    const disableFullscreen = () =>{
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }


    return (<>
        {url&&type==="other"?<></>:<div className="player_loading" ref={loadingRef} ><FontAwesomeIcon className="player_icon-loading" icon={faCircleNotch} /></div>}
        <div className="player_container">
            <div className="player_content" ref={playerRef}>
                {url&&type==="encripted"?<ShakaPlayer ref={encriptedPlayerRef}  />:<></>}
                {url&&type==="other"?<iframe id="player_iframe" title="PlayerJs" width="100%" height="100%" className="player_iframe" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true" webkit-playsinline="true" playsInline={true} allow="autoplay" src={url?url:""}></iframe>:<></>}
                {url&&(type==="vimeo"||type==="mp4"||type==="youtube")? <ReactPlayer 
                        ref={vimeoPlayerRef}
                        playing={playing} 
                        volume={volume/100} 
                        playsinline={true} 
                        onReady={()=>setLiveOrNot()}
                        controls={false}
                        onBuffer={()=>loadingOn()}
                        onDuration={time=>setTimeTotal(time)}
                        onBufferEnd={()=>loadingOff()}
                        onPause={()=>backoutScreen(true)}
                        onPlay={()=>backoutScreen(false)}
                        onEnded={endedRoutine}
                        width="100%" 
                        height="100%" 
                        url={url}
                    />
                :<></>}
            </div>
            {url&&((type==="other"))?<></>:(
            <div ref={interfaceRef}  onMouseMove={()=>setControllTimer()} className="player_interface">
                
                
                <div ref={screenRef} onClick={screenPlayPause} className={playing?"player_interface_screen controll_off":"player_interface_screen"}>
                    <FontAwesomeIcon className="player_icon" icon={playing?faPauseCircle:faPlayCircle} />
                    <div className="player_info" ><h3>{htmlDecode(title?title:"")}</h3><p>{htmlDecode(description?description:"")}</p></div>
                </div>


                <div ref={controllRef} onMouseLeave={()=>setOnControll(false)} onMouseEnter={()=>setOnControll(true)} className="player_interface_controls">
                    <div onClick={playPause} className="controls_play">
                        <FontAwesomeIcon className="player_icon" icon={playing?faPause:faPlay} />
                    </div>
                    <div  className="controls_progress">
                        <div ref={progressRef}  className="controls_progress_container">
                            <div style={{width: showProgressBar()}} className="controls_progress_inner"></div>
                        </div>
                    </div>
                    {horizontalView||!mobile?
                        <span ref={timerRef} className="controls_timer">
                            {formateTimer(formateSeconds(timePassed))}
                            {!mobile?" / "+formateTimer(formateSeconds(timeTotal))
                            :""}
                        </span>
                        :<></>
                    }
                    <div  className="controls_volume">
                        <FontAwesomeIcon onClick={munteUnmut} className="player_icon" icon={!volume?faVolumeMute:faVolumeUp} />
                        <div  ref={volumeRef} className="controls_volume_container">
                            <div style={{width: showVolumeBar()}} className="controls_volume_inner"></div>
                        </div>
                    </div>
                    {!mobile?
                        <div  className="controls_fullScreen">
                            <FontAwesomeIcon onClick={fullScreenSmallScreen} className="player_icon" icon={fullScreen?faCompress:faExpand} />
                        </div>
                        :<></>
                    }
                </div>
            </div>
            )}
        </div>
       </>
    )
}
