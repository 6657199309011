import React,{useState, useEffect, useRef, useContext} from 'react';
import './style.scss';

import { useHistory } from "react-router-dom";
import { audioDataContext } from "../../context";

import {  faPlayCircle, faPauseCircle, faVolumeUp, faVolumeOff, faRandom, faRetweet, faStepForward, faStepBackward, faTimes, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {formateTimeAudio, htmlDecode, getRandomList} from "../../utils"


export default function AudioPlayer() {
    const history = useHistory();

    const { audio, song } = useContext(audioDataContext)
    const {currentSongContext,setCurrentSongContext} = song
    const {audioContext,setAudioContext} = audio

    const [backToListUrl, setBackToListUrl] = useState(null)
    const [randomList, setRandomList] = useState(null)
    const [randomPosition, setRandomPosition] = useState(null)
    const [timerAudio, setTimerAudio] = useState(null)
    const [playingSong, setPlayingSong] = useState(null)
    const [playing, setPlaying] = useState(null)
    const [volume, setVolume] = useState(50)
    const [random, setRandom] = useState(false)
    const [repeat, setRepeat] = useState(false)
    const [totalTime, setTotalTime] = useState(0)
    const [passedTime, setPassedTime] = useState(0)

    const AudioPlayerRef = useRef()
    const AudioProgressRef = useRef()
    const AudioVolumeRef = useRef()

    const nextSong = () => {
        const arrayTotalPositions = audioContext.seccionContent.length-1

        if(random&&repeat!==1) setNextRandom(randomPosition,arrayTotalPositions)
        else if(repeat) setNextRepeat(repeat,currentSongContext,arrayTotalPositions)
        else setNext(currentSongContext,arrayTotalPositions)
    }
    const previousSong = () => {
        const arrayTotalPositions = audioContext.seccionContent.length-1

        if(random&&repeat!==1) setPreviousRandom(randomPosition,arrayTotalPositions)
        else if(repeat) setPreviousRepeat(repeat,currentSongContext,arrayTotalPositions)
        else setPrevious(currentSongContext,arrayTotalPositions)
    }
    const setNext = (actualPosition,arrayTotalPositions) => {
        if(actualPosition<arrayTotalPositions) setCurrentSongContext(actualPosition+1)
        else {
            setPassedTime(0)
            setPlaying(false)
        }
    }
    const setNextRepeat = (repeat,actualPosition,arrayTotalPositions) => {
        if(repeat===1) updateTrack()
        else if(arrayTotalPositions===actualPosition){
            setCurrentSongContext(0)
        }else setNext(actualPosition,arrayTotalPositions)
    }
    const setPrevious = (actualPosition,arrayTotalPositions) => {
        if(actualPosition>0) setCurrentSongContext(actualPosition-1)
        else {
            setPassedTime(arrayTotalPositions)
            setPlaying(false)
        }
    }
    const setPreviousRepeat = (repeat,actualPosition,arrayTotalPositions) => {
        if(repeat===1) updateTrack()
        else if(actualPosition===0){
            setCurrentSongContext(arrayTotalPositions)
        }else setPrevious(actualPosition,arrayTotalPositions)
    }
    const setNextRandom = (randomPosition,arrayTotalPositions) => {
        if(!randomList) {
            const list = getRandomList(arrayTotalPositions)
            setRandomList(list)
            setRandomPosition(0)
            setCurrentSongContext(list[0])}
        else {
            if(randomPosition<arrayTotalPositions-1){
                setCurrentSongContext(randomList[randomPosition+1])
                setRandomPosition(randomPosition+1)}
            else {
                setCurrentSongContext(randomList[0])
                setRandomPosition(0)
            }
        }
    }
    const setPreviousRandom = (randomPosition,arrayTotalPositions) => {
        if(!randomList) {
            const list = getRandomList(arrayTotalPositions)
            setRandomList(list)
            setRandomPosition(0)
            setCurrentSongContext(list[0])}
        else {
            if(randomPosition!==0){
                setCurrentSongContext(randomList[randomPosition-1])
                setRandomPosition(randomPosition-1)}
            else {
                setCurrentSongContext(randomList[arrayTotalPositions-1])
                setRandomPosition(arrayTotalPositions-1)
            }
        }
    }
    const randomOnOff = () => {
      setRandom(random=>{
         return !random
      })
    }
    const repeatOnOff = () => {
      setRepeat(repeat=>{
        if(repeat===1) return false
        else if(repeat===true) return 1
        else return true
      })
    }
    const playPause = () => {
        setPlaying(playing=>!playing)
    }
    const muteUnmute = () => {
        setVolume(volume=>{
            if(volume===0) return 50
            else return 0
        })
    }
    const closeAudioPlayer = () => {
        setAudioContext(null)
    }
    const updateTrack = () => {
        AudioPlayerRef.current.load()
        setPlaying(false)
        setPassedTime(0)
    }
    const audioTimer = () =>{
        clearTimeout(timerAudio)
       
        setTimerAudio(setInterval(() => {

            setPassedTime(seconds=>{
                const totalAudioTime = Math.floor(totalTime)
                if (seconds<totalAudioTime) return seconds+1
                else return seconds
            })

        }, 1000))
        
    }
    const AudioSeek = (click,total) =>{
            
        setTotalTime(timeTotal=>{
            const newTime = Math.floor((timeTotal*click)/total);

            AudioPlayerRef.current.currentTime = newTime

            setPassedTime(newTime)
            return timeTotal
            }
        )
       
    }
    const volumeSeek = (click,total) =>{
        setTotalTime(timeTotal=>{
            const newVolume = Math.floor((100*click)/total);
            setVolume(newVolume)
            return timeTotal
            }
        )
    }
    const showProgressBar = () =>{
        return passedTime*100/totalTime+"%"
    }
    const showVolumeBar = () =>{
        return volume+"%"
    }
    const backToList = () =>{
        
        if(history.location.pathname!==backToListUrl)history.push(backToListUrl)
        
    }

    useEffect(() => {
        setBackToListUrl(history.location.pathname)
        if(audioContext){

            setPlayingSong(audioContext.seccionContent[0])
        
            setPassedTime(0)

            if(AudioPlayerRef.current){
                AudioPlayerRef.current.volume = 0.5
                AudioPlayerRef.current.onloadeddata = function() {
                    setTotalTime(AudioPlayerRef.current.duration)
                    playPause()
                };
                
                AudioPlayerRef.current.onended = function() {
                    nextSong()
                };
                AudioVolumeRef.current.addEventListener('click', function(event) {
                    const click = event.offsetX;
                    const total = event.target.clientWidth;
                    volumeSeek(click,total)
                    
                });
                AudioProgressRef.current.addEventListener('click', function(event) {
                    const click = event.offsetX;
                    const total = event.target.clientWidth;
                    AudioSeek(click,total)
                    
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioContext])

    useEffect(() => {
        
        if(AudioPlayerRef.current){
            if(playing){
                AudioPlayerRef.current.play()
                audioTimer()
            }else {
                AudioPlayerRef.current.pause()
                clearTimeout(timerAudio)
            }

            setTotalTime(AudioPlayerRef.current.duration)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing])

    useEffect(() => {
        
        if(AudioPlayerRef.current){
            AudioPlayerRef.current.volume = volume/100
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume])

    useEffect(() => {

        if(audioContext){
            setPlayingSong(audioContext.seccionContent[currentSongContext])
            updateTrack()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSongContext,audioContext])

    return (
        audioContext?(<>
        <audio  ref={AudioPlayerRef} id="audio_element">
            <source src={playingSong?.url} type="audio/mpeg"/>
        </audio>
        <div className="audioPlayer_container">
            <div className="audioPlayer_albumImage" >
                <img  alt={audioContext.titulo+ " album"}src={audioContext.imagem}></img>
                <div  className="audioPlayer_albumTitle" >
                    <h3>{htmlDecode(audioContext.titulo)}</h3>
                    <span>{htmlDecode(playingSong?.titulo)}</span>
                </div>
            </div>
            <div>
                <div className="audioPlayer_controllers">
                    <div className="audioPlayer_icon_repeat">
                        <FontAwesomeIcon className={repeat?"audioPlayer_icon-active":"audioPlayer_icon"} onClick={repeatOnOff} icon={faRetweet} />
                        {repeat===1?<span className="audioPlayer_icon_one">1</span>:<></>}
                    </div>

                    <FontAwesomeIcon className="audioPlayer_controller" onClick={previousSong} icon={faStepBackward} />
                    <FontAwesomeIcon className="audioPlayer_controller-play" onClick={()=>playPause()} icon={playing?faPauseCircle:faPlayCircle} />
                    <FontAwesomeIcon className="audioPlayer_controller" onClick={nextSong} icon={faStepForward} />
                    
                    <FontAwesomeIcon className={random?"audioPlayer_icon-active":"audioPlayer_icon"} onClick={randomOnOff} icon={faRandom} />
                    
                    <div className="audio_volume">
                        <FontAwesomeIcon className="audioPlayer_controller" onClick={()=>muteUnmute()} icon={volume===0?faVolumeOff:faVolumeUp} />
                        <div ref={AudioVolumeRef} className="audio_volume_container">
                            <div style={{width: showVolumeBar()}} className="audio_volume_inner"></div>
                        </div>
                    </div>
                </div>
                <div className="audio_progress">
                    {formateTimeAudio(passedTime)}
                    <div ref={AudioProgressRef} className="audio_progress_container">
                        <div style={{width: showProgressBar()}} className="audio_progress_inner"></div>
                    </div>
                    {formateTimeAudio(totalTime)}
                </div>
            </div>
            <div className="audioPlayer_closeBtn">
                <FontAwesomeIcon onClick={backToList} className="audioPlayer_controller" icon={faList} />
                <FontAwesomeIcon onClick={closeAudioPlayer} className="audioPlayer_controller" icon={faTimes} />
            </div>

       </div></>):<></>
       
    )
}
