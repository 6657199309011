import React, { useEffect, useContext } from 'react'
import useNavigationSetup from '../../hooks/useNavigationSetup';
import {layoutContext} from '../../context';
import {userContext} from '../../context';

import Perfil from '../../components/perfil'

export default function Profile(props) {
    const { layout, neoLang, textList } = useContext(layoutContext)
    const { layoutData } = layout
    const { language } = neoLang
    const { text } = textList
  
    const { user , prof, country } = useContext(userContext)
    const { userData } = user
    const { professions } = prof
    const { countries } = country

    const setNavi = useNavigationSetup()

    const hardCodeData = props.match.params.mdi_id?layoutData:null;
    const mdi_id = props.match.params.mdi_id;
    
    if(hardCodeData){
        hardCodeData["campos_dinamicos"] = setCustomPerfilHardCode(layoutData)
    }

    function setCustomPerfilHardCode(layoutPerfil) {
        const mdi_id = layoutPerfil.mdiId
        
        if (mdi_id==="292") return [
          {
              nome: "Profissão",
              obrigatorio: true,
              placeholder: "Profissão",
              slug: "profissao",
              tipo: "profissao",
            },{
              nome: "CRM",
              obrigatorio: true,
              condicao:["profissao","medico"],
              placeholder: "CRM",
              slug: "crm",
              tipo: "idnumerico",
            }
        ]
        else if (mdi_id==="286") return [
            {
              nome: "Profissão",
              obrigatorio: true,
              placeholder: "Profissão",
              slug: "profissao",
              tipo: "profissao",
            },{
              nome: "CRM",
              obrigatorio: true,
              condicao:["profissao","medico"],
              placeholder: "CRM",
              slug: "crm",
              tipo: "idnumerico",
            },{
              nome: "Carteira de estudante",
              obrigatorio: true,
              condicao:["profissao","academico"],
              placeholder: "Carteira de Estudante",
              slug: "carteira-profissional-estudante",
              tipo: "binary",
            },{
              nome: "Carteira profissional",
              obrigatorio: true,
              condicao:["profissao","profissional-saude"],
              placeholder: "Carteira de Estudante",
              slug: "carteira-profissional-estudante",
              tipo: "binary",
            }
        ]
        else if (mdi_id==="229"){
          return [
            {
              nome: "Nascimento",
              obrigatorio: true,
              placeholder: "Nascimento",
              slug: "nascimento",
              tipo: "nascimento",
            },{
              nome: "Sexo",
              obrigatorio: true,
              opcoes: [
                {codigo: "Feminino", valor: "Feminino"},
                {codigo: "Masculino", valor: "Masculino"},
                {codigo: "Outro", valor: "Outro"},
              ],
              placeholder: "Sexo",
              slug: "sexo",
              tipo: "radio",
            },{
              nome: "País",
              obrigatorio: true,
              placeholder: "Carteira de Estudante",
              slug: "pais",
              tipo: "pais",
            },{
              nome: "Celular",
              obrigatorio: true,
              placeholder: "Celular",
              slug: "celular",
              tipo: "telefone",
            }
        ]}
  
        else return layoutPerfil.campos_dinamicos;
    }
    
    useEffect(() => {
        const navigation={
            logo: layoutData.slug?false:true,
            menu:true,
            hideBars:layoutData.slug?true:false,
            back: true,
          }
          setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div className="page__placement">
            
            <Perfil {...props} logo={false} lang={language} mdi_id={mdi_id} countriesPerfil={countries} professionsPerfil={professions} userPerfil={userData} layoutPerfil={hardCodeData} text={text}></Perfil>
            
        </div>
    )
}
