import React, { useContext } from 'react';
import { getUserData, getNeosList, } from "../api"
import {userContext} from '../context';

export default function PopupPerfil({
    popupMessage,
    setPopupMessag,
    iosAgent,
    text,
    status,
    listaGrupos,
    listaGruposComprar,
    setStatusPopup,
    logoutPerfil,
    popupBtn
}) {

    const { user } = useContext(userContext)
    const { setUserData } = user

    const closePopup = () => {
        setPopupMessag("")
        setStatusPopup("disable")
    }
    const showProductList = () => {
        if(listaGrupos?.length>0){
            return (<>
                <h3>{text.seusProdutos}</h3>
                <ul className="perfil_listaProdutos">
                {listaGrupos?.map(item => {
                    return <li key={item}>{item}</li>
                    })}
                </ul>
                </>
            )
        }else return <p>{text.semListaProdutos}</p>
    }
    const showSubscriptionsList = () => {
        if(listaGruposComprar?.length>0){
            return (<>
                <h3>{text.assineProdutos}</h3>
                <ul className="perfil_comprarAssinaturas">
                {listaGruposComprar?.map(item => {
                    return <li key={item.imagem}>
                            <a  href={item.url} target={iosAgent?'_self':'_blank'} >
                            <img alt={item.nome} src={item.imagem}/>
                            </a></li>
                    })}
                </ul>
                </>
            )
        }
    }
   
    async function refreshUserData () {
        setUserData(status)
        closePopup()
    }
    return (  
        <div id="popupConponentperfil" className={`popup__background ${status}`}>
            <div className="form__popup">
                <div className="popup__title">
                    {popupMessage?popupMessage:""}
                    {popupMessage?<></>:showSubscriptionsList()}
                    {popupMessage?<></>:showProductList()}
                    </div>                        
                {popupBtn?(
              
              <button onClick={
                        popupBtn==="logout"?logoutPerfil:popupBtn==="refresh"?refreshUserData:closePopup
                        } className="form__btn--secundary">
                    Ok
                </button>):<></>}
            </div>
        </div>
    );
}
 